<template>
  <div id="wrap">
    <div class="top_app_down" v-if="showAppDownloadPopup">
      <div class="top_app_down_wrap">
        <div class="app_down_img"><img src="../../../static/mobile/images/kway_app_down_m.png"></div>
        <div class="app_down_text">
          <div class="title">APP 첫 로그인 3천원 웰컴 쿠폰 혜택</div>
          <div class="con">K-WAY APP 설치 후 더 나은 쇼핑을 경험해보세요.</div>
        </div>
      </div>
      
      <div class="app_down_close" @click="closeAppPopup()"><img src="../../../static/mobile/images/kway_app_close_m.png"></div>
    </div>
    <template>
      <header-main/>
      <!-- <header-main v-if="header === 'header-main'"/> -->
      <!-- <header-page v-else-if="header === 'header-page'"/> -->
      <!-- <header-category v-else-if="header === 'header-category'"/> -->

      <main class="contents" :style="{'padding-top': showAppDownloadPopup? contentsPaddingTop : '0px'}">
        <nuxt :key="$pageUtil.getKey()"/>
      </main>

      <!--<top-button/>-->

      <mobile-footer v-if="$store.state.layout.hasFooter"/>
    </template>
    <div
      ref="intro"
      class="intro"
      v-if="mobileIntro && onIntroRun"
    >
      <img :src="getImageUrl(mobileIntro.link)" />
    </div>
    <!-- cre.ma / 공통 스크립트 (PC) / 스크립트를 수정할 경우 연락주세요 (support@cre.ma) -->
    <script>(function(i,s,o,g,r,a,m){if(s.getElementById(g)){return};a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.id=g;a.async=1;a.src=r;m.parentNode.insertBefore(a,m)})(window,document,'script','crema-jssdk','//widgets.cre.ma/niceclaup.co.kr/mobile/init.js');</script>
  </div>
</template>

<script>
import HeaderMain from "@/components/commons/mobile/headers/HeaderMain.vue";
// import HeaderPage from "@/components/commons/mobile/headers/HeaderPage.vue";
// import HeaderCategory from "@/components/commons/mobile/headers/HeaderCategory.vue";
import MobileFooter from "@/components/commons/mobile/MobileFooter.vue";
import TopButton from "@/components/commons/TopButton.vue";

export default {

  components: {
    HeaderMain,
    // HeaderPage,
    // HeaderCategory,
    MobileFooter,
    TopButton,
  },

  data: () => ({
    mobileIntro: undefined,
    onIntroRun: false,
    showMain: false,
    showAppDownloadPopup: false,
    contentsPaddingTop: '70px'
  }),


  head: function() {
    // console.log(this.userProfile)
    return {
      link: [
        {
          rel: 'preload',
          href: '/mobile/css/common.css',
          as: 'style'
        },
        {
          rel: 'stylesheet',
          href: '/mobile/css/common.css',
        },
      ],
      script: [
        {
          type: 'text/javascript',
          src: '//t1.daumcdn.net/adfit/static/kp.js'
        }
      ],
    }
  },

  computed: {
    header() {
      return (
        "header-" + String(this.$store.state.layout.headerComponentName || "").toLocaleLowerCase()
      );
    },
  },

  methods:{
    closeAppPopup(){
      try {
        let el = document.getElementsByClassName('header_wrap')[0];
        el.style.marginTop = '0px';
  
        //window.sessionStorage.setItem("NICECLAUP_APP_DOWNLOAD_POPUP", "TRUE");
        setCookie("NICECLAUP_APP_DOWNLOAD_POPUP", "TRUE", 1);
        this.showAppDownloadPopup = false;
      } catch (e) {}
    }
  },

  beforeMount() {
    let session = this.$store.state.session;
    let userId =  null;
    let userName = null;

    if(session.isMember) {
      userName = `${session.profile.name}`;
      userId = `${session.profile.loginId}`
    }

    try {
      window.cremaAsyncInit = function () {
        crema.init(userId, userName)  
        // console.log(`crema init on mobile\n userId: ${userId}\n userName: ${userName}`)
      }
      window.cremaAsyncInit()
    } catch (error) {
      // console.log('crema init error\n', error)
    }
  },


  async mounted() {
    this.mobileIntro = await this.$axios.$get("/api/main/mobile-intro");
    let intro = window.sessionStorage.getItem("KAPPA_INTRO");
    if (!this.$store.state.layout.isApp && (this.$device.isAndroid || this.$device.isIos)) {
        //let val = window.sessionStorage.getItem("NICECLAUP_APP_DOWNLOAD_POPUP");
        let val = getCookie("NICECLAUP_APP_DOWNLOAD_POPUP");
        if (val != "TRUE") {
          try {
            /**
             * 상단앱다운 내리고 토스트팝업으로 진행중.
             * 상단팝업 사용하려면 주석 해제하면됨 - jkjang 2024-07-10
             */
            /*
            let el = document.getElementsByClassName('header_wrap')[0];
            el.style.marginTop = this.contentsPaddingTop;
            this.showAppDownloadPopup = true;
            */
          } catch (e) {}
        }
      }

    // 이것이 인트로 소스이다...
    // 더러븜...
    if (!intro && this.mobileIntro && this.$route.fullPath.match(/^\/main.*$/)) {
      this.onIntroRun = true;
      window.sessionStorage.setItem("KAPPA_INTRO", "T");

      document.body.classList.add("lock-scroll");

      setTimeout(() => {
        this.showMain = true;
      }, 100);

      setTimeout(() => {
        try {
          this.$refs.intro.classList.add("fadeout");
        } catch (e) {
        }

        setTimeout(() => {
          this.onIntroRun = false;
          document.body.classList.remove("lock-scroll");
        }, 500);
      }, 1500); // About 2s
    } else {
      window.sessionStorage.setItem("KAPPA_INTRO", "T");
      this.onIntroRun = false;
      this.showMain = true;
    }

    try{
      if (typeof kakaoPixel !== "undefined") {
        kakaoPixel('8540025084970616763').pageView();
      }
    } catch (e){

    }
  },
};

var setCookie = function(name, value, exp) {
  var date = new Date();
  date.setTime(date.getTime() + (exp*24*60*60*1000) );
  document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';
};

var getCookie = function(name) {
  var value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return value? value[2] : null;
};
</script>

<style lang="scss" scoped>
.intro {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  opacity: 1;

  &.fadeout {
    opacity: 0;
    transition: visibility 0s 500ms, opacity 500ms linear;
  }

  img {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}
</style>
