import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _62267824 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _3efd6002 = () => interopDefault(import('../pages/category/index.vue' /* webpackChunkName: "pages/category/index" */))
const _447d66f8 = () => interopDefault(import('../pages/community.vue' /* webpackChunkName: "pages/community" */))
const _4e91c81e = () => interopDefault(import('../pages/community/instagram/index.vue' /* webpackChunkName: "pages/community/instagram/index" */))
const _48856b52 = () => interopDefault(import('../pages/community/review-award/index.vue' /* webpackChunkName: "pages/community/review-award/index" */))
const _3757f3da = () => interopDefault(import('../pages/conversion/index.vue' /* webpackChunkName: "pages/conversion/index" */))
const _400c11c8 = () => interopDefault(import('../pages/criteo/index.vue' /* webpackChunkName: "pages/criteo/index" */))
const _651010c2 = () => interopDefault(import('../pages/customer.vue' /* webpackChunkName: "pages/customer" */))
const _69f009ae = () => interopDefault(import('../pages/customer/faq/index.vue' /* webpackChunkName: "pages/customer/faq/index" */))
const _bb6d74e2 = () => interopDefault(import('../pages/customer/guide/index.vue' /* webpackChunkName: "pages/customer/guide/index" */))
const _69925c59 = () => interopDefault(import('../pages/customer/main.vue' /* webpackChunkName: "pages/customer/main" */))
const _27ca1f0a = () => interopDefault(import('../pages/customer/notice/index.vue' /* webpackChunkName: "pages/customer/notice/index" */))
const _20637c77 = () => interopDefault(import('../pages/customer/qna/index.vue' /* webpackChunkName: "pages/customer/qna/index" */))
const _8bc3def2 = () => interopDefault(import('../pages/customer/qna/write/index.vue' /* webpackChunkName: "pages/customer/qna/write/index" */))
const _02c155e6 = () => interopDefault(import('../pages/customer/notice/_id/index.vue' /* webpackChunkName: "pages/customer/notice/_id/index" */))
const _10c4309f = () => interopDefault(import('../pages/customer/qna/_id.vue' /* webpackChunkName: "pages/customer/qna/_id" */))
const _73d5a06c = () => interopDefault(import('../pages/event/index.vue' /* webpackChunkName: "pages/event/index" */))
const _01621234 = () => interopDefault(import('../pages/gift/index.vue' /* webpackChunkName: "pages/gift/index" */))
const _69c50a28 = () => interopDefault(import('../pages/goods/index.vue' /* webpackChunkName: "pages/goods/index" */))
const _507354a4 = () => interopDefault(import('../pages/join/index.vue' /* webpackChunkName: "pages/join/index" */))
const _1d7d3e2c = () => interopDefault(import('../pages/kway-people/index.vue' /* webpackChunkName: "pages/kway-people/index" */))
const _1612b30a = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _4daf947a = () => interopDefault(import('../pages/main.vue' /* webpackChunkName: "pages/main" */))
const _562ceecc = () => interopDefault(import('../pages/main/best/index.vue' /* webpackChunkName: "pages/main/best/index" */))
const _0a5d8cd8 = () => interopDefault(import('../pages/main/community.vue' /* webpackChunkName: "pages/main/community" */))
const _7e84e2ba = () => interopDefault(import('../pages/main/community/instagram/index.vue' /* webpackChunkName: "pages/main/community/instagram/index" */))
const _3bcafb36 = () => interopDefault(import('../pages/main/community/review-award/index.vue' /* webpackChunkName: "pages/main/community/review-award/index" */))
const _370187f0 = () => interopDefault(import('../pages/main/event/index.vue' /* webpackChunkName: "pages/main/event/index" */))
const _7f4c7c4e = () => interopDefault(import('../pages/main/new/index.vue' /* webpackChunkName: "pages/main/new/index" */))
const _6bc51000 = () => interopDefault(import('../pages/main/review/index.vue' /* webpackChunkName: "pages/main/review/index" */))
const _a6cd8d22 = () => interopDefault(import('../pages/main/sale/index.vue' /* webpackChunkName: "pages/main/sale/index" */))
const _528272b2 = () => interopDefault(import('../pages/main/special/index.vue' /* webpackChunkName: "pages/main/special/index" */))
const _dd8df822 = () => interopDefault(import('../pages/main/today/index.vue' /* webpackChunkName: "pages/main/today/index" */))
const _6d528142 = () => interopDefault(import('../pages/my-page.vue' /* webpackChunkName: "pages/my-page" */))
const _104e4664 = () => interopDefault(import('../pages/my-page/alarm/index.vue' /* webpackChunkName: "pages/my-page/alarm/index" */))
const _7ec64a8f = () => interopDefault(import('../pages/my-page/claim/index.vue' /* webpackChunkName: "pages/my-page/claim/index" */))
const _17262e86 = () => interopDefault(import('../pages/my-page/coupon.vue' /* webpackChunkName: "pages/my-page/coupon" */))
const _0e348b2f = () => interopDefault(import('../pages/my-page/coupon/unused.vue' /* webpackChunkName: "pages/my-page/coupon/unused" */))
const _c742bc54 = () => interopDefault(import('../pages/my-page/coupon/used.vue' /* webpackChunkName: "pages/my-page/coupon/used" */))
const _379b3c90 = () => interopDefault(import('../pages/my-page/gift.vue' /* webpackChunkName: "pages/my-page/gift" */))
const _4530d432 = () => interopDefault(import('../pages/my-page/gift/receive/index.vue' /* webpackChunkName: "pages/my-page/gift/receive/index" */))
const _6423799a = () => interopDefault(import('../pages/my-page/gift/send/index.vue' /* webpackChunkName: "pages/my-page/gift/send/index" */))
const _5a790806 = () => interopDefault(import('../pages/my-page/gift/receive/accept/index.vue' /* webpackChunkName: "pages/my-page/gift/receive/accept/index" */))
const _459fdaf8 = () => interopDefault(import('../pages/my-page/gift/receive/cancel/index.vue' /* webpackChunkName: "pages/my-page/gift/receive/cancel/index" */))
const _5a8cd3d8 = () => interopDefault(import('../pages/my-page/gift/receive/claim/index.vue' /* webpackChunkName: "pages/my-page/gift/receive/claim/index" */))
const _3245cb32 = () => interopDefault(import('../pages/my-page/gift/receive/delivery/index.vue' /* webpackChunkName: "pages/my-page/gift/receive/delivery/index" */))
const _df3ee2be = () => interopDefault(import('../pages/my-page/gift/receive/exchange/index.vue' /* webpackChunkName: "pages/my-page/gift/receive/exchange/index" */))
const _fa5d03a4 = () => interopDefault(import('../pages/my-page/gift/receive/return/index.vue' /* webpackChunkName: "pages/my-page/gift/receive/return/index" */))
const _13c0f7e5 = () => interopDefault(import('../pages/my-page/gift/send/cancel/index.vue' /* webpackChunkName: "pages/my-page/gift/send/cancel/index" */))
const _44baa787 = () => interopDefault(import('../pages/my-page/gift/send/claim/index.vue' /* webpackChunkName: "pages/my-page/gift/send/claim/index" */))
const _06d93e42 = () => interopDefault(import('../pages/my-page/gift/send/delivery/index.vue' /* webpackChunkName: "pages/my-page/gift/send/delivery/index" */))
const _75174ef8 = () => interopDefault(import('../pages/my-page/gift/receive/accept/complete/index.vue' /* webpackChunkName: "pages/my-page/gift/receive/accept/complete/index" */))
const _3e2ca774 = () => interopDefault(import('../pages/my-page/gift/receive/cancel/complete/index.vue' /* webpackChunkName: "pages/my-page/gift/receive/cancel/complete/index" */))
const _0a1cada5 = () => interopDefault(import('../pages/my-page/gift/receive/claim/delivery/index.vue' /* webpackChunkName: "pages/my-page/gift/receive/claim/delivery/index" */))
const _c99bc494 = () => interopDefault(import('../pages/my-page/gift/receive/delivery/exchange/index.vue' /* webpackChunkName: "pages/my-page/gift/receive/delivery/exchange/index" */))
const _b51462fa = () => interopDefault(import('../pages/my-page/gift/receive/delivery/return/index.vue' /* webpackChunkName: "pages/my-page/gift/receive/delivery/return/index" */))
const _4cbe06bd = () => interopDefault(import('../pages/my-page/gift/receive/exchange/complete/index.vue' /* webpackChunkName: "pages/my-page/gift/receive/exchange/complete/index" */))
const _355d45d0 = () => interopDefault(import('../pages/my-page/gift/receive/return/complete/index.vue' /* webpackChunkName: "pages/my-page/gift/receive/return/complete/index" */))
const _cf746c0e = () => interopDefault(import('../pages/my-page/gift/send/cancel/complete/index.vue' /* webpackChunkName: "pages/my-page/gift/send/cancel/complete/index" */))
const _2804caae = () => interopDefault(import('../pages/my-page/gift/send/delivery/exchange/index.vue' /* webpackChunkName: "pages/my-page/gift/send/delivery/exchange/index" */))
const _5cd1ec36 = () => interopDefault(import('../pages/my-page/gift/send/delivery/return/index.vue' /* webpackChunkName: "pages/my-page/gift/send/delivery/return/index" */))
const _721166ba = () => interopDefault(import('../pages/my-page/gift/receive/claim/delivery/exchange/index.vue' /* webpackChunkName: "pages/my-page/gift/receive/claim/delivery/exchange/index" */))
const _36b856a0 = () => interopDefault(import('../pages/my-page/gift/receive/claim/delivery/return/index.vue' /* webpackChunkName: "pages/my-page/gift/receive/claim/delivery/return/index" */))
const _9bc3e508 = () => interopDefault(import('../pages/my-page/gift/receive/claim/_id.vue' /* webpackChunkName: "pages/my-page/gift/receive/claim/_id" */))
const _27ea40a2 = () => interopDefault(import('../pages/my-page/gift/send/claim/_id.vue' /* webpackChunkName: "pages/my-page/gift/send/claim/_id" */))
const _edb5355c = () => interopDefault(import('../pages/my-page/gift/receive/_id/index.vue' /* webpackChunkName: "pages/my-page/gift/receive/_id/index" */))
const _79db90f6 = () => interopDefault(import('../pages/my-page/gift/send/_id/index.vue' /* webpackChunkName: "pages/my-page/gift/send/_id/index" */))
const _2f59b7b2 = () => interopDefault(import('../pages/my-page/gift/receive/_id/delivery/index.vue' /* webpackChunkName: "pages/my-page/gift/receive/_id/delivery/index" */))
const _01541b54 = () => interopDefault(import('../pages/my-page/gift/send/_id/delivery/index.vue' /* webpackChunkName: "pages/my-page/gift/send/_id/delivery/index" */))
const _035e4c5a = () => interopDefault(import('../pages/my-page/inquiry/index.vue' /* webpackChunkName: "pages/my-page/inquiry/index" */))
const _9b0bd8f2 = () => interopDefault(import('../pages/my-page/lpoint/index.vue' /* webpackChunkName: "pages/my-page/lpoint/index" */))
const _047a6c99 = () => interopDefault(import('../pages/my-page/main.vue' /* webpackChunkName: "pages/my-page/main" */))
const _d72308c6 = () => interopDefault(import('../pages/my-page/mileage/index.vue' /* webpackChunkName: "pages/my-page/mileage/index" */))
const _d50809be = () => interopDefault(import('../pages/my-page/order/index.vue' /* webpackChunkName: "pages/my-page/order/index" */))
const _5d88d792 = () => interopDefault(import('../pages/my-page/qna/index.vue' /* webpackChunkName: "pages/my-page/qna/index" */))
const _daef65d8 = () => interopDefault(import('../pages/my-page/recently-goods/index.vue' /* webpackChunkName: "pages/my-page/recently-goods/index" */))
const _fc693690 = () => interopDefault(import('../pages/my-page/review.vue' /* webpackChunkName: "pages/my-page/review" */))
const _83acf1b6 = () => interopDefault(import('../pages/my-page/review/writeable/index.vue' /* webpackChunkName: "pages/my-page/review/writeable/index" */))
const _3e36d543 = () => interopDefault(import('../pages/my-page/review/written/index.vue' /* webpackChunkName: "pages/my-page/review/written/index" */))
const _05f7901c = () => interopDefault(import('../pages/my-page/review-edit/index.vue' /* webpackChunkName: "pages/my-page/review-edit/index" */))
const _129b9e57 = () => interopDefault(import('../pages/my-page/wish-list/index.vue' /* webpackChunkName: "pages/my-page/wish-list/index" */))
const _1d6e2662 = () => interopDefault(import('../pages/my-page/alarm/setting.vue' /* webpackChunkName: "pages/my-page/alarm/setting" */))
const _2e63d38a = () => interopDefault(import('../pages/my-page/claim/delivery/index.vue' /* webpackChunkName: "pages/my-page/claim/delivery/index" */))
const _d5b8aa2c = () => interopDefault(import('../pages/my-page/guest/order/index.vue' /* webpackChunkName: "pages/my-page/guest/order/index" */))
const _6ec4007e = () => interopDefault(import('../pages/my-page/order/cancel/index.vue' /* webpackChunkName: "pages/my-page/order/cancel/index" */))
const _ba12b190 = () => interopDefault(import('../pages/my-page/order/delivery/index.vue' /* webpackChunkName: "pages/my-page/order/delivery/index" */))
const _01116aa7 = () => interopDefault(import('../pages/my-page/order/exchange/index.vue' /* webpackChunkName: "pages/my-page/order/exchange/index" */))
const _a814b898 = () => interopDefault(import('../pages/my-page/order/return/index.vue' /* webpackChunkName: "pages/my-page/order/return/index" */))
const _76154f72 = () => interopDefault(import('../pages/my-page/qna/write/index.vue' /* webpackChunkName: "pages/my-page/qna/write/index" */))
const _1c88f1a1 = () => interopDefault(import('../pages/my-page/user/edit/index.vue' /* webpackChunkName: "pages/my-page/user/edit/index" */))
const _ba678ce8 = () => interopDefault(import('../pages/my-page/user/withdrawal/index.vue' /* webpackChunkName: "pages/my-page/user/withdrawal/index" */))
const _b4c80f44 = () => interopDefault(import('../pages/my-page/claim/delivery/exchange/index.vue' /* webpackChunkName: "pages/my-page/claim/delivery/exchange/index" */))
const _2a66332b = () => interopDefault(import('../pages/my-page/claim/delivery/return/index.vue' /* webpackChunkName: "pages/my-page/claim/delivery/return/index" */))
const _3c105d80 = () => interopDefault(import('../pages/my-page/order/cancel/complete/index.vue' /* webpackChunkName: "pages/my-page/order/cancel/complete/index" */))
const _711d2f20 = () => interopDefault(import('../pages/my-page/order/delivery/exchange/index.vue' /* webpackChunkName: "pages/my-page/order/delivery/exchange/index" */))
const _009c7fbd = () => interopDefault(import('../pages/my-page/order/delivery/return/index.vue' /* webpackChunkName: "pages/my-page/order/delivery/return/index" */))
const _78fd5177 = () => interopDefault(import('../pages/my-page/order/exchange/complete/index.vue' /* webpackChunkName: "pages/my-page/order/exchange/complete/index" */))
const _def811ec = () => interopDefault(import('../pages/my-page/order/return/complete/index.vue' /* webpackChunkName: "pages/my-page/order/return/complete/index" */))
const _c2628ac2 = () => interopDefault(import('../pages/my-page/user/edit/password/index.vue' /* webpackChunkName: "pages/my-page/user/edit/password/index" */))
const _6598b692 = () => interopDefault(import('../pages/my-page/claim/_id.vue' /* webpackChunkName: "pages/my-page/claim/_id" */))
const _61b4ab76 = () => interopDefault(import('../pages/my-page/inquiry/_id/index.vue' /* webpackChunkName: "pages/my-page/inquiry/_id/index" */))
const _ac9ec4e8 = () => interopDefault(import('../pages/my-page/order/_id/index.vue' /* webpackChunkName: "pages/my-page/order/_id/index" */))
const _97818f42 = () => interopDefault(import('../pages/my-page/qna/_id.vue' /* webpackChunkName: "pages/my-page/qna/_id" */))
const _a078e24c = () => interopDefault(import('../pages/my-page/review-edit/_id.vue' /* webpackChunkName: "pages/my-page/review-edit/_id" */))
const _c4eae5a6 = () => interopDefault(import('../pages/my-page/order/_id/delivery/index.vue' /* webpackChunkName: "pages/my-page/order/_id/delivery/index" */))
const _624dc417 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _11656420 = () => interopDefault(import('../pages/order/index.vue' /* webpackChunkName: "pages/order/index" */))
const _45a17fb5 = () => interopDefault(import('../pages/promotion/index.vue' /* webpackChunkName: "pages/promotion/index" */))
const _5c0a1d60 = () => interopDefault(import('../pages/redirect/index.vue' /* webpackChunkName: "pages/redirect/index" */))
const _8925fb28 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _2be4bbea = () => interopDefault(import('../pages/special/index.vue' /* webpackChunkName: "pages/special/index" */))
const _619508ad = () => interopDefault(import('../pages/footer/adfilm/index.vue' /* webpackChunkName: "pages/footer/adfilm/index" */))
const _70841657 = () => interopDefault(import('../pages/footer/brand/index.vue' /* webpackChunkName: "pages/footer/brand/index" */))
const _369e08e4 = () => interopDefault(import('../pages/footer/collection/index.vue' /* webpackChunkName: "pages/footer/collection/index" */))
const _303efdd0 = () => interopDefault(import('../pages/footer/partner/index.vue' /* webpackChunkName: "pages/footer/partner/index" */))
const _4181366c = () => interopDefault(import('../pages/footer/privacypolicy/index.vue' /* webpackChunkName: "pages/footer/privacypolicy/index" */))
const _2ab1499e = () => interopDefault(import('../pages/footer/store/index.vue' /* webpackChunkName: "pages/footer/store/index" */))
const _e6f55b76 = () => interopDefault(import('../pages/footer/story/index.vue' /* webpackChunkName: "pages/footer/story/index" */))
const _5bd2d752 = () => interopDefault(import('../pages/footer/terms/index.vue' /* webpackChunkName: "pages/footer/terms/index" */))
const _47ebd88a = () => interopDefault(import('../pages/gift/complete/index.vue' /* webpackChunkName: "pages/gift/complete/index" */))
const _3efad4c8 = () => interopDefault(import('../pages/gift/kcpCommon/index.vue' /* webpackChunkName: "pages/gift/kcpCommon/index" */))
const _08b8c70b = () => interopDefault(import('../pages/gift/no-member/index.vue' /* webpackChunkName: "pages/gift/no-member/index" */))
const _220e0141 = () => interopDefault(import('../pages/gift/return/index.vue' /* webpackChunkName: "pages/gift/return/index" */))
const _ff5951c6 = () => interopDefault(import('../pages/gift/vbankDeposit/index.vue' /* webpackChunkName: "pages/gift/vbankDeposit/index" */))
const _6bcb061b = () => interopDefault(import('../pages/join/form/index.vue' /* webpackChunkName: "pages/join/form/index" */))
const _41c2dd9e = () => interopDefault(import('../pages/join/simple-form/index.vue' /* webpackChunkName: "pages/join/simple-form/index" */))
const _08018fae = () => interopDefault(import('../pages/join/sso/index.vue' /* webpackChunkName: "pages/join/sso/index" */))
const _eb4022bc = () => interopDefault(import('../pages/join/success/index.vue' /* webpackChunkName: "pages/join/success/index" */))
const _49da417e = () => interopDefault(import('../pages/join/welcome/index.vue' /* webpackChunkName: "pages/join/welcome/index" */))
const _1f89bb06 = () => interopDefault(import('../pages/link/apple.vue' /* webpackChunkName: "pages/link/apple" */))
const _7a073488 = () => interopDefault(import('../pages/link/naver.vue' /* webpackChunkName: "pages/link/naver" */))
const _0d981574 = () => interopDefault(import('../pages/login/apple/index.vue' /* webpackChunkName: "pages/login/apple/index" */))
const _13850b6f = () => interopDefault(import('../pages/login/kakao/index.vue' /* webpackChunkName: "pages/login/kakao/index" */))
const _3e2d3f7c = () => interopDefault(import('../pages/login/naver/index.vue' /* webpackChunkName: "pages/login/naver/index" */))
const _6b346dad = () => interopDefault(import('../pages/member/active/index.vue' /* webpackChunkName: "pages/member/active/index" */))
const _a7fe4006 = () => interopDefault(import('../pages/member/email-rvc/index.vue' /* webpackChunkName: "pages/member/email-rvc/index" */))
const _6d22f9d8 = () => interopDefault(import('../pages/member/expire-password/index.vue' /* webpackChunkName: "pages/member/expire-password/index" */))
const _7c5e4704 = () => interopDefault(import('../pages/member/find-id/index.vue' /* webpackChunkName: "pages/member/find-id/index" */))
const _83fb7f80 = () => interopDefault(import('../pages/member/reset-password/index.vue' /* webpackChunkName: "pages/member/reset-password/index" */))
const _78c0d850 = () => interopDefault(import('../pages/member/sol-temp/index.vue' /* webpackChunkName: "pages/member/sol-temp/index" */))
const _08701fc4 = () => interopDefault(import('../pages/order/complete/index.vue' /* webpackChunkName: "pages/order/complete/index" */))
const _06a1afb4 = () => interopDefault(import('../pages/order/kcpCommon/index.vue' /* webpackChunkName: "pages/order/kcpCommon/index" */))
const _5738562a = () => interopDefault(import('../pages/order/manual/index.vue' /* webpackChunkName: "pages/order/manual/index" */))
const _5f40bc12 = () => interopDefault(import('../pages/order/no-member/index.vue' /* webpackChunkName: "pages/order/no-member/index" */))
const _c91a2456 = () => interopDefault(import('../pages/order/return/index.vue' /* webpackChunkName: "pages/order/return/index" */))
const _327184b1 = () => interopDefault(import('../pages/order/vbankDeposit/index.vue' /* webpackChunkName: "pages/order/vbankDeposit/index" */))
const _47b3f1ee = () => interopDefault(import('../pages/redirect/lpoint/index.vue' /* webpackChunkName: "pages/redirect/lpoint/index" */))
const _a010efc6 = () => interopDefault(import('../pages/gift/return/complete/index.vue' /* webpackChunkName: "pages/gift/return/complete/index" */))
const _9cec0616 = () => interopDefault(import('../pages/login/modal/LoginErrorModal.vue' /* webpackChunkName: "pages/login/modal/LoginErrorModal" */))
const _7cb06a49 = () => interopDefault(import('../pages/login/naver/index_bak.vue' /* webpackChunkName: "pages/login/naver/index_bak" */))
const _e377989a = () => interopDefault(import('../pages/order/manual/complete/index.vue' /* webpackChunkName: "pages/order/manual/complete/index" */))
const _75d18209 = () => interopDefault(import('../pages/order/return/complete/index.vue' /* webpackChunkName: "pages/order/return/complete/index" */))
const _8bc87f02 = () => interopDefault(import('../pages/footer/terms/_id.vue' /* webpackChunkName: "pages/footer/terms/_id" */))
const _623b62f7 = () => interopDefault(import('../pages/goods/write-qna/_id.vue' /* webpackChunkName: "pages/goods/write-qna/_id" */))
const _665eb552 = () => interopDefault(import('../pages/event/_id/index.vue' /* webpackChunkName: "pages/event/_id/index" */))
const _942e08fc = () => interopDefault(import('../pages/news/_id/index.vue' /* webpackChunkName: "pages/news/_id/index" */))
const _0b99b820 = () => interopDefault(import('../pages/promotion/_id/index.vue' /* webpackChunkName: "pages/promotion/_id/index" */))
const _49b7d6e1 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cart",
    component: _62267824,
    name: "cart"
  }, {
    path: "/category",
    component: _3efd6002,
    name: "category"
  }, {
    path: "/community",
    component: _447d66f8,
    name: "community",
    children: [{
      path: "instagram",
      component: _4e91c81e,
      name: "community-instagram"
    }, {
      path: "review-award",
      component: _48856b52,
      name: "community-review-award"
    }]
  }, {
    path: "/conversion",
    component: _3757f3da,
    name: "conversion"
  }, {
    path: "/criteo",
    component: _400c11c8,
    name: "criteo"
  }, {
    path: "/customer",
    component: _651010c2,
    name: "customer",
    children: [{
      path: "faq",
      component: _69f009ae,
      name: "customer-faq"
    }, {
      path: "guide",
      component: _bb6d74e2,
      name: "customer-guide"
    }, {
      path: "main",
      component: _69925c59,
      name: "customer-main"
    }, {
      path: "notice",
      component: _27ca1f0a,
      name: "customer-notice"
    }, {
      path: "qna",
      component: _20637c77,
      name: "customer-qna"
    }, {
      path: "qna/write",
      component: _8bc3def2,
      name: "customer-qna-write"
    }, {
      path: "notice/:id",
      component: _02c155e6,
      name: "customer-notice-id"
    }, {
      path: "qna/:id",
      component: _10c4309f,
      name: "customer-qna-id"
    }]
  }, {
    path: "/event",
    component: _73d5a06c,
    name: "event"
  }, {
    path: "/gift",
    component: _01621234,
    name: "gift"
  }, {
    path: "/goods",
    component: _69c50a28,
    name: "goods"
  }, {
    path: "/join",
    component: _507354a4,
    name: "join"
  }, {
    path: "/kway-people",
    component: _1d7d3e2c,
    name: "kway-people"
  }, {
    path: "/login",
    component: _1612b30a,
    name: "login"
  }, {
    path: "/main",
    component: _4daf947a,
    name: "main",
    children: [{
      path: "best",
      component: _562ceecc,
      name: "main-best"
    }, {
      path: "community",
      component: _0a5d8cd8,
      name: "main-community",
      children: [{
        path: "instagram",
        component: _7e84e2ba,
        name: "main-community-instagram"
      }, {
        path: "review-award",
        component: _3bcafb36,
        name: "main-community-review-award"
      }]
    }, {
      path: "event",
      component: _370187f0,
      name: "main-event"
    }, {
      path: "new",
      component: _7f4c7c4e,
      name: "main-new"
    }, {
      path: "review",
      component: _6bc51000,
      name: "main-review"
    }, {
      path: "sale",
      component: _a6cd8d22,
      name: "main-sale"
    }, {
      path: "special",
      component: _528272b2,
      name: "main-special"
    }, {
      path: "today",
      component: _dd8df822,
      name: "main-today"
    }]
  }, {
    path: "/my-page",
    component: _6d528142,
    name: "my-page",
    children: [{
      path: "alarm",
      component: _104e4664,
      name: "my-page-alarm"
    }, {
      path: "claim",
      component: _7ec64a8f,
      name: "my-page-claim"
    }, {
      path: "coupon",
      component: _17262e86,
      name: "my-page-coupon",
      children: [{
        path: "unused",
        component: _0e348b2f,
        name: "my-page-coupon-unused"
      }, {
        path: "used",
        component: _c742bc54,
        name: "my-page-coupon-used"
      }]
    }, {
      path: "gift",
      component: _379b3c90,
      name: "my-page-gift",
      children: [{
        path: "receive",
        component: _4530d432,
        name: "my-page-gift-receive"
      }, {
        path: "send",
        component: _6423799a,
        name: "my-page-gift-send"
      }, {
        path: "receive/accept",
        component: _5a790806,
        name: "my-page-gift-receive-accept"
      }, {
        path: "receive/cancel",
        component: _459fdaf8,
        name: "my-page-gift-receive-cancel"
      }, {
        path: "receive/claim",
        component: _5a8cd3d8,
        name: "my-page-gift-receive-claim"
      }, {
        path: "receive/delivery",
        component: _3245cb32,
        name: "my-page-gift-receive-delivery"
      }, {
        path: "receive/exchange",
        component: _df3ee2be,
        name: "my-page-gift-receive-exchange"
      }, {
        path: "receive/return",
        component: _fa5d03a4,
        name: "my-page-gift-receive-return"
      }, {
        path: "send/cancel",
        component: _13c0f7e5,
        name: "my-page-gift-send-cancel"
      }, {
        path: "send/claim",
        component: _44baa787,
        name: "my-page-gift-send-claim"
      }, {
        path: "send/delivery",
        component: _06d93e42,
        name: "my-page-gift-send-delivery"
      }, {
        path: "receive/accept/complete",
        component: _75174ef8,
        name: "my-page-gift-receive-accept-complete"
      }, {
        path: "receive/cancel/complete",
        component: _3e2ca774,
        name: "my-page-gift-receive-cancel-complete"
      }, {
        path: "receive/claim/delivery",
        component: _0a1cada5,
        name: "my-page-gift-receive-claim-delivery"
      }, {
        path: "receive/delivery/exchange",
        component: _c99bc494,
        name: "my-page-gift-receive-delivery-exchange"
      }, {
        path: "receive/delivery/return",
        component: _b51462fa,
        name: "my-page-gift-receive-delivery-return"
      }, {
        path: "receive/exchange/complete",
        component: _4cbe06bd,
        name: "my-page-gift-receive-exchange-complete"
      }, {
        path: "receive/return/complete",
        component: _355d45d0,
        name: "my-page-gift-receive-return-complete"
      }, {
        path: "send/cancel/complete",
        component: _cf746c0e,
        name: "my-page-gift-send-cancel-complete"
      }, {
        path: "send/delivery/exchange",
        component: _2804caae,
        name: "my-page-gift-send-delivery-exchange"
      }, {
        path: "send/delivery/return",
        component: _5cd1ec36,
        name: "my-page-gift-send-delivery-return"
      }, {
        path: "receive/claim/delivery/exchange",
        component: _721166ba,
        name: "my-page-gift-receive-claim-delivery-exchange"
      }, {
        path: "receive/claim/delivery/return",
        component: _36b856a0,
        name: "my-page-gift-receive-claim-delivery-return"
      }, {
        path: "receive/claim/:id",
        component: _9bc3e508,
        name: "my-page-gift-receive-claim-id"
      }, {
        path: "send/claim/:id",
        component: _27ea40a2,
        name: "my-page-gift-send-claim-id"
      }, {
        path: "receive/:id",
        component: _edb5355c,
        name: "my-page-gift-receive-id"
      }, {
        path: "send/:id",
        component: _79db90f6,
        name: "my-page-gift-send-id"
      }, {
        path: "receive/:id/delivery",
        component: _2f59b7b2,
        name: "my-page-gift-receive-id-delivery"
      }, {
        path: "send/:id/delivery",
        component: _01541b54,
        name: "my-page-gift-send-id-delivery"
      }]
    }, {
      path: "inquiry",
      component: _035e4c5a,
      name: "my-page-inquiry"
    }, {
      path: "lpoint",
      component: _9b0bd8f2,
      name: "my-page-lpoint"
    }, {
      path: "main",
      component: _047a6c99,
      name: "my-page-main"
    }, {
      path: "mileage",
      component: _d72308c6,
      name: "my-page-mileage"
    }, {
      path: "order",
      component: _d50809be,
      name: "my-page-order"
    }, {
      path: "qna",
      component: _5d88d792,
      name: "my-page-qna"
    }, {
      path: "recently-goods",
      component: _daef65d8,
      name: "my-page-recently-goods"
    }, {
      path: "review",
      component: _fc693690,
      name: "my-page-review",
      children: [{
        path: "writeable",
        component: _83acf1b6,
        name: "my-page-review-writeable"
      }, {
        path: "written",
        component: _3e36d543,
        name: "my-page-review-written"
      }]
    }, {
      path: "review-edit",
      component: _05f7901c,
      name: "my-page-review-edit"
    }, {
      path: "wish-list",
      component: _129b9e57,
      name: "my-page-wish-list"
    }, {
      path: "alarm/setting",
      component: _1d6e2662,
      name: "my-page-alarm-setting"
    }, {
      path: "claim/delivery",
      component: _2e63d38a,
      name: "my-page-claim-delivery"
    }, {
      path: "guest/order",
      component: _d5b8aa2c,
      name: "my-page-guest-order"
    }, {
      path: "order/cancel",
      component: _6ec4007e,
      name: "my-page-order-cancel"
    }, {
      path: "order/delivery",
      component: _ba12b190,
      name: "my-page-order-delivery"
    }, {
      path: "order/exchange",
      component: _01116aa7,
      name: "my-page-order-exchange"
    }, {
      path: "order/return",
      component: _a814b898,
      name: "my-page-order-return"
    }, {
      path: "qna/write",
      component: _76154f72,
      name: "my-page-qna-write"
    }, {
      path: "user/edit",
      component: _1c88f1a1,
      name: "my-page-user-edit"
    }, {
      path: "user/withdrawal",
      component: _ba678ce8,
      name: "my-page-user-withdrawal"
    }, {
      path: "claim/delivery/exchange",
      component: _b4c80f44,
      name: "my-page-claim-delivery-exchange"
    }, {
      path: "claim/delivery/return",
      component: _2a66332b,
      name: "my-page-claim-delivery-return"
    }, {
      path: "order/cancel/complete",
      component: _3c105d80,
      name: "my-page-order-cancel-complete"
    }, {
      path: "order/delivery/exchange",
      component: _711d2f20,
      name: "my-page-order-delivery-exchange"
    }, {
      path: "order/delivery/return",
      component: _009c7fbd,
      name: "my-page-order-delivery-return"
    }, {
      path: "order/exchange/complete",
      component: _78fd5177,
      name: "my-page-order-exchange-complete"
    }, {
      path: "order/return/complete",
      component: _def811ec,
      name: "my-page-order-return-complete"
    }, {
      path: "user/edit/password",
      component: _c2628ac2,
      name: "my-page-user-edit-password"
    }, {
      path: "claim/:id",
      component: _6598b692,
      name: "my-page-claim-id"
    }, {
      path: "inquiry/:id",
      component: _61b4ab76,
      name: "my-page-inquiry-id"
    }, {
      path: "order/:id",
      component: _ac9ec4e8,
      name: "my-page-order-id"
    }, {
      path: "qna/:id",
      component: _97818f42,
      name: "my-page-qna-id"
    }, {
      path: "review-edit/:id?",
      component: _a078e24c,
      name: "my-page-review-edit-id"
    }, {
      path: "order/:id/delivery",
      component: _c4eae5a6,
      name: "my-page-order-id-delivery"
    }]
  }, {
    path: "/news",
    component: _624dc417,
    name: "news"
  }, {
    path: "/order",
    component: _11656420,
    name: "order"
  }, {
    path: "/promotion",
    component: _45a17fb5,
    name: "promotion"
  }, {
    path: "/redirect",
    component: _5c0a1d60,
    name: "redirect"
  }, {
    path: "/search",
    component: _8925fb28,
    name: "search"
  }, {
    path: "/special",
    component: _2be4bbea,
    name: "special"
  }, {
    path: "/footer/adfilm",
    component: _619508ad,
    name: "footer-adfilm"
  }, {
    path: "/footer/brand",
    component: _70841657,
    name: "footer-brand"
  }, {
    path: "/footer/collection",
    component: _369e08e4,
    name: "footer-collection"
  }, {
    path: "/footer/partner",
    component: _303efdd0,
    name: "footer-partner"
  }, {
    path: "/footer/privacypolicy",
    component: _4181366c,
    name: "footer-privacypolicy"
  }, {
    path: "/footer/store",
    component: _2ab1499e,
    name: "footer-store"
  }, {
    path: "/footer/story",
    component: _e6f55b76,
    name: "footer-story"
  }, {
    path: "/footer/terms",
    component: _5bd2d752,
    name: "footer-terms"
  }, {
    path: "/gift/complete",
    component: _47ebd88a,
    name: "gift-complete"
  }, {
    path: "/gift/kcpCommon",
    component: _3efad4c8,
    name: "gift-kcpCommon"
  }, {
    path: "/gift/no-member",
    component: _08b8c70b,
    name: "gift-no-member"
  }, {
    path: "/gift/return",
    component: _220e0141,
    name: "gift-return"
  }, {
    path: "/gift/vbankDeposit",
    component: _ff5951c6,
    name: "gift-vbankDeposit"
  }, {
    path: "/join/form",
    component: _6bcb061b,
    name: "join-form"
  }, {
    path: "/join/simple-form",
    component: _41c2dd9e,
    name: "join-simple-form"
  }, {
    path: "/join/sso",
    component: _08018fae,
    name: "join-sso"
  }, {
    path: "/join/success",
    component: _eb4022bc,
    name: "join-success"
  }, {
    path: "/join/welcome",
    component: _49da417e,
    name: "join-welcome"
  }, {
    path: "/link/apple",
    component: _1f89bb06,
    name: "link-apple"
  }, {
    path: "/link/naver",
    component: _7a073488,
    name: "link-naver"
  }, {
    path: "/login/apple",
    component: _0d981574,
    name: "login-apple"
  }, {
    path: "/login/kakao",
    component: _13850b6f,
    name: "login-kakao"
  }, {
    path: "/login/naver",
    component: _3e2d3f7c,
    name: "login-naver"
  }, {
    path: "/member/active",
    component: _6b346dad,
    name: "member-active"
  }, {
    path: "/member/email-rvc",
    component: _a7fe4006,
    name: "member-email-rvc"
  }, {
    path: "/member/expire-password",
    component: _6d22f9d8,
    name: "member-expire-password"
  }, {
    path: "/member/find-id",
    component: _7c5e4704,
    name: "member-find-id"
  }, {
    path: "/member/reset-password",
    component: _83fb7f80,
    name: "member-reset-password"
  }, {
    path: "/member/sol-temp",
    component: _78c0d850,
    name: "member-sol-temp"
  }, {
    path: "/order/complete",
    component: _08701fc4,
    name: "order-complete"
  }, {
    path: "/order/kcpCommon",
    component: _06a1afb4,
    name: "order-kcpCommon"
  }, {
    path: "/order/manual",
    component: _5738562a,
    name: "order-manual"
  }, {
    path: "/order/no-member",
    component: _5f40bc12,
    name: "order-no-member"
  }, {
    path: "/order/return",
    component: _c91a2456,
    name: "order-return"
  }, {
    path: "/order/vbankDeposit",
    component: _327184b1,
    name: "order-vbankDeposit"
  }, {
    path: "/redirect/lpoint",
    component: _47b3f1ee,
    name: "redirect-lpoint"
  }, {
    path: "/gift/return/complete",
    component: _a010efc6,
    name: "gift-return-complete"
  }, {
    path: "/login/modal/LoginErrorModal",
    component: _9cec0616,
    name: "login-modal-LoginErrorModal"
  }, {
    path: "/login/naver/index_bak",
    component: _7cb06a49,
    name: "login-naver-index_bak"
  }, {
    path: "/order/manual/complete",
    component: _e377989a,
    name: "order-manual-complete"
  }, {
    path: "/order/return/complete",
    component: _75d18209,
    name: "order-return-complete"
  }, {
    path: "/footer/terms/:id",
    component: _8bc87f02,
    name: "footer-terms-id"
  }, {
    path: "/goods/write-qna/:id?",
    component: _623b62f7,
    name: "goods-write-qna-id"
  }, {
    path: "/event/:id",
    component: _665eb552,
    name: "event-id"
  }, {
    path: "/news/:id",
    component: _942e08fc,
    name: "news-id"
  }, {
    path: "/promotion/:id",
    component: _0b99b820,
    name: "promotion-id"
  }, {
    path: "/",
    component: _49b7d6e1,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
