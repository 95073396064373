export default {
  state: () => ({
    scrollYDirection: 'UP'
  }),

  getters: {
    isScrollDown: (state) => {
      return state.scrollYDirection === 'DOWN'
    }
  },
  
  mutations: {
    set(state, direction) {
      state.scrollYDirection = direction
    }
  }
}