<template>
  <section class="sub_para02">
    <header-main v-if="$device.isMobile"/>
    <div class="error" :class="{ false: 'w800' }[$device.isMobile]">
      <!-- 오류 메시지 -->
      <div class="error_status tc">
        <p class="fail subcon_tit" :class="{ true: 'mobile' }[$device.isMobile]">
          {{ error.title }}
        </p>
        <span class="dp_block" v-html-custom="errorMessage"> </span>
        <!-- 안내문구 -->
        <div class="text_info">
          <ul>
            <li>
              쇼핑몰 이용관련문의는 <span class="mo_br"></span><span class="txt_red">고객센터({{ formatPhone($store.state.session.site.csTelNo) }})</span>로 연락해주세요.
            </li>
          </ul>
        </div>
        <!--// 안내문구 -->
      </div>
      <!--// 오류 메시지 -->
    </div>
    <div class="tc error_mobtn" :style="{ true: 'margin:0 auto;width:800px;' }[$device.isDesktop]">
      <button
        type="button"
        class="bt_rwhite bt_wide mgr10"
        @click.stop="$router.go(-1)"
        v-if="showPrev"
      >
        이전 화면으로
      </button>
      <button
        type="button"
        class="bt_rblack bt_wide"
        @click.stop="$router.push({ path: '/' })"
        v-if="showHome"
      >
        K-WAY 홈
      </button>
    </div>
  </section>
</template>

<script>
import Default from "./default";
import HeaderMain from '@/components/commons/mobile/headers/HeaderMain';

const ERROR_MESSAGE_MAP = {
  404: '페이지를 찾을 수 없습니다.',
}

export default {
  components: {HeaderMain},
  props: ["error"],
  extends: Default,
  computed: {
    showPrev() {
      if (this.error.showPrev === undefined) {
        return true;
      } else {
        return this.error.showPrev;
      }
    },
    showHome() {
      if (this.error.showHome === undefined) {
        return true;
      } else {
        return this.error.showHome;
      }
    },
    errorMessage() {
      return ERROR_MESSAGE_MAP[this.error.statusCode] || this.error.message
    },
  }
};
</script>

<style>
 
.error_status {
  overflow: hidden;
  padding: 30px 0 100px;
  margin: auto auto 0;
}

.error_status > span {margin-top:5px;}


.error_status p {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  padding-top:86px;
  margin:0 auto;
  width: 500px;
}

.error_status p.fail {
  background: url("/images/icon_nodata.svg") no-repeat center top;
  background-size: 56px;
}

.error_status p.fail.mobile {
  width: auto;
}

.error .bt_last button {
  margin-right: 0.5rem;
}

.error .bt_last button:last-child {
  margin-right: 0;
}

.error_status .text_info li::before {
  display: none;
}

.error_status .text_info {margin-top:30px;}
.error_status .text_info li {color:#20202c; font-size:12px;}
.error_status .text_info li span {font-size:12px; font-weight:400;}

</style>
