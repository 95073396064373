<template>
  <div class="comon_popup cart_popup" :style="{ display: display }">
    <div class="popup">
      <p class="pop_tit md">
        MY CART
        <span
          v-if="$store.state.session.profile.cartGoodsCount > 0"
          class="cart_badge"
        >
          {{ $store.state.session.profile.cartGoodsCount }}
        </span>
      </p>
      <div class="cart_utill">
        <label class="checkbox_wrap">
          <input type="checkbox" :checked="isAllChecked()" @click.stop="toggleCheckAll()">
          <!-- <input type="checkbox" > -->
          <i class="check_icon"></i>
          <span>전체선택 </span>
        </label>
        <div class="remove_util">
          <button type="button" @click.stop="deleteSelectedGoodsFromCart()">선택삭제</button>
          <button type="button" @click.stop="deleteSoldOutGoodsFromCart()">품절삭제</button>
        </div>
      </div>
      <ul class="cart_itemli" v-if="goodsList.length > 0">
        <!--        <ul class="cart_itemli">-->
        <li v-for="(goods, i) in goodsList" :key="i">
          <label class="checkbox_wrap">
            <input type="checkbox" :checked="isChecked(goods)" @click.stop="toggleCheck(goods)">
            <i class="check_icon"></i>
          </label>
          <div class="row_probox">
            <div class="thumb">
              <nuxt-link :to="{ path: `/goods`, query: { id: goods.goodsId } }">
                <img
                  :src="getImageUrl(goods.imgPath)"
                  onerror="this.src='/images/no-img.jpg'"
                />
              </nuxt-link>
            </div>
            <div class="pro_sminfo">
              <span class="sort_tit" style="font-size: 14px; color: #a7a7a7;">{{ genderStr(goods) }}</span>
              <p class="pro_name" style="font-size: 14px;">{{ goods.goodsNm }}</p>
              <!--p class="pro_color">블랙</p-->
              <div class="price" style="margin-top: 10px;" v-if="saleAmtPercent(goods) > 0">
                <strong class="sale_txt" style="font-size: 14px; margin-right: 5px; color: #f30000; font-weight: 600;">{{ formatPercentGoods(saleAmtPercent(goods), 'floor') }}</strong>
                <strong class="discount" style="font-size: 14px; margin-right: 5px; color: #20202c; font-weight: 600;">{{ formatAmount(goods.saleAmt) }}</strong>
                <span class="original" style="font-size: 14px; margin-right: 5px; text-decoration: line-through;">{{ formatAmount(beforeDiscountOrgAmt(goods)) }}</span>
                <!--strong class="discount">{{ formatAmount(goods.saleAmt) }}</strong>
                <span class="original" v-if="goods.prmtAmt === 0 && goods.saleAmt - goods.orgPrice !== 0">{{ formatAmount(beforeDiscountOrgAmt(goods)) }}</span>
                <strong class="sale_txt" v-if="goods.prmtAmt === 0 && goods.saleAmt - goods.orgPrice !== 0">{{ formatPercentGoods(saleAmtPercent(goods), 'floor') }}</strong-->
              </div>
              <div class="price" style="margin-top: 10px;" v-else>
                <span class="original" style="font-size: 14px;">{{ formatAmount(beforeDiscountOrgAmt(goods)) }}</span>
              </div>
              <div class="pro_option">
                <p class="optin">옵션 : {{ getGoodsOptionsAsCommaString(goods.itemNm) }}</p>
                <p class="pro_count">수량 : <span>{{ goods.buyQty }}</span></p>
              </div>
            </div>
          </div>
          <div class="bt_2nd">
            <button type="button" class="bt_rwhite" @click.stop="openOptionModal(goods)">옵션변경</button>
            <button
              type="button"
              class="bt_rgray"
              v-if="goods.salePsbCd === '30'"
            >
              품절
            </button>
            <!-- 품절일 때 -->
            <button
              type="button"
              class="bt_rblack"
              @click.stop="orderGoods(goods)"
              v-else
            >
              바로 구매
            </button>
          </div>
          <button class="remove_pro">닫기</button>
          <button
            type="button"
            class="remove_pro"
            @click.stop="removeGoods(goods)"
          >
            삭제
          </button>
        </li>
      </ul>
      <ul class="cart_itemli pd_mg" v-else>
        장바구니에 담긴 상품이 없습니다.
      </ul>
      <div class="ship_fee">
        <p>배송비 <span>{{ formatAmount(totalDlvrAmt) }}</span>원</p>
        <span v-if="deliveryPolicyList.length">
          ({{ formatAmount(deliveryPolicyList[0].buyPrc) }}원 미만 결제 시 {{ formatAmount(deliveryPolicyList[0].dlvrAmt) }}원)
        </span>
      </div>
      <div class="pay_infoli">
        <strong>결제 예정 금액</strong>
        <ul>
          <li>
            <span>결제금액</span>
            <p>{{ formatAmount(totalGoodsAmt + totalPrmtDcAmt) }}원</p>
          </li>
          <li>
            <span>배송비</span>
            <p>+{{ formatAmount(totalDlvrAmt) }}원</p>
          </li>
          <li class="pdb15">
            <span>총 할인금액</span>
            <p class="discount">{{ formatAmount(totalDcAmt) }}원</p>
          </li>
          <!-- TODO: 상품할인 데이터 확인 -->
          <li class="sm_ft pd30 border">
            <span>상품할인</span>
            <p>0원</p>
          </li>
          <li v-if="$store.state.session.isEmployee">
            <span>임직원 할인</span>
            <p>{{ formatAmount(-totalOfrstfDcAmt) }}원</p>
          </li>
          <li class="total_price">
            <span>총 결제 금액</span>
            <p>{{ formatAmount(totalAmt) }}원</p>
          </li>
        </ul>
      </div>
      <!-- 카드혜택 -->
      <div class="bt_cardevent pdlr16">
        <button
          v-for="(event, index) in cardEventList"
          :key="`card_event_${index}`"
          @click.prevent="openCardEventModal(event)"
          type="button"
          class="bt_rwhite"
        >
          {{ event.ttl }}
        </button>
      </div>
      <!--// 카드혜택 -->
      <div class="gray_polibox">
        <ul class="txt_bfli">
          <li>장바구니에 담긴 상품은 14일동안 보관됩니다. 14일이 지난 상품은 자동 삭제됩니다.</li>
          <li>장바구니에 최대 100개까지 상품 보관이 가능하며, 실제 구매시에는 가격이나 혜택이 변동 될 수 있습니다.</li>
          <li>장바구니에 담은 상품이 판매  종료가 되었을 경우 자동 삭제됩니다.</li>
        </ul>
      </div>
      <template v-if="goodsList.length >= 0">
        <div class="with_paypro para50">
          <p class="pop_tit sm">함께 구매하면 좋은 상품</p>
          <div class="scrollbar_proli">
            <client-only>
              <swiper ref="swiperOptions" :options="recommGoodsList.length == 1 ? Object.assign(swiperOptions, { init:false}) : swiperOptions" class="swiper-wrapper">
                <swiper-slide class="swiper-slide" v-for="(goods, index) in recommGoodsList" :key="`goods_${index}`">
                  <Goods :goods="goods" :is-none-options="['color']" inflow-method="CSTRT" :customTextStyle="{fontSizePrice: '13px'}"></Goods>
                </swiper-slide>
              </swiper>
            </client-only>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
        </div>
      </template>

      <button type="button" class="bttp_cart"
              @click.prevent="goCart" >
        <span>장바구니 자세히보기</span>
      </button>

      <label class="checkbox_wrap order_chk" style="display:none;">
        <input type="checkbox" required v-model="onlineShoppingAgreement">
        <i class="check_icon"></i>
        <span>상품 주문 정보에 동의 합니다. <em class="txt_red">(필수)</em> <br>(전자상거래법 제 8조 제 2항)</span>
      </label>
      <!-- 주문하기 버튼 -->
      <div class="bt_2nd">
        <button class="bt_rwhite bt_gift" @click.stop="onSubmit(2)"><span>선물하기</span></button>
        <button class="bt_rblack" @click.stop="onSubmit(1)">구매 하기</button>
      </div>
      <!--// 주문하기 버튼 -->
      <a href="#;" class="pop_close" @click.prevent="closeCartModal()">팝업 닫기</a>
    </div>
  </div>
</template>

<script>
import MobileCartOption from "@/components/pages/cart/modal/MobileCartOption";
import WebOptionModal from "@/components/pages/cart/modal/WebOptionModal";
import HtmlModal from "@/components/commons/modal/HtmlModal";
import numeral from "numeral";
import Goods from "@/components/goods/default/Desktop";

export default {
  components:{
    Goods,
  },
  props:{
    display:String,
    onOrderStart:Boolean,
    },
  data: () => ({
    goodsList: [],
    selectedGoodsList: [],
    advertiseGoodsList: [],
    swiperOptions: {
      slidesPerView: "auto",
      slidesPerGroup: 1,
      spaceBetween: 10,
      scrollbar: {
        hide: false
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    },
    totalGoodsAmt: 0,
    totalOfrstfDcAmt: 0,
    totalPrmtDcAmt: 0,
    totalDlvrAmt: 0,
    totalCartCnt : 0,
    onQtyChange: false,
    onlineShoppingAgreement: false,
    recommGoodsList: [],
    site: undefined,
    cardEventList: [],
    isOrderOnProcess: false,
    totalCheckCnt:0,
  }),

  computed: {


    totalDcAmt() {
      return this.totalPrmtDcAmt + this.totalOfrstfDcAmt;
    },

    totalAmt() {
      return this.totalGoodsAmt - this.totalDcAmt + this.totalDlvrAmt;
    },

    deliveryPolicyList() {
      try {
        return this.site.DeliveryPolicyList.filter(x => x.dlvrcStdCd === "30");
      } catch (e) {
        return [];
      }
    }
  },
  watch:{
    "display":{
      immediate: true,
      deep: true,
      async handler(value) {
        //console.log('value',value)
         if(value === "block") {
           this.goodsList =await this.$cart.get();
           this.site = this.$axios.$get("/api/site")
           this.cardEventList = this.$axios.$get("/api/event/card")
           await this.updateGoodsList();
           await this.getTotalCartCount();
           this.executeMobonScript();
           this.executeCriteo();
           this.fetchRecommendGoodsList();
         }
      }
    }
  },

  async created() {

    // this.goodsList = this.$cart.get();
    // this.site = this.$axios.$get("/api/site")
    // this.cardEventList = this.$axios.$get("/api/event/card")
    // this.updateGoodsList();

    /*
    try{

      if (typeof window !== "undefined") {
          // 장바구니 전환스크립트
          let goodsTotalAmt = 0;
          if(this.goodsList){
            this.goodsList.forEach( goods => {
              goodsTotalAmt += (goods.saleAmt * goods.buyQty);
            })
          }
          if(goodsTotalAmt == 0) goodsTotalAmt = "1"
          var _nasa={};
          if(window.wcs) _nasa["cnv"] = wcs.cnv("3",goodsTotalAmt + "");

          // 전환용 공통추가
          if (!wcs_add) var wcs_add={};
            wcs_add["wa"] = "s_2741689f968b";
            if (!_nasa) var _nasa={};

            if(window.wcs){
              wcs.inflow("niceclaup.co.kr");
              wcs_do(_nasa);
            }
        }

      } catch (e) {}
    */


    // await this.fetchRecommendGoodsList();

  },
   mounted() {
    this.executeMobonScript();
    this.executeCriteo();
   },

  beforeDestroy() {
    try {
      window.ENP_VAR = undefined;
    } catch (e) {
      // // console.error(e);
    }
  },
  methods: {
    executeMobonScript() {
      //console.log("this.$env.IS_LIVE", this.$env.IS_LIVE)
      if (this.$env.IS_LIVE != 'true') return;
      try {
        let goodsList = [], totalBuyAmt = 0, totalBuyQty = 0;
        this.cart.goodsList.forEach(goods => {
          goodsList.push(`{
  productCode: '${goods.compGoodsId}',
  productName: '${goods.goodsNm}',
  price: '${goods.saleAmt}',
  dcPrice: '${goods.saleAmt - goods.prmtDcAmt}',
  qty: '${goods.buyQty}'
}
          `);
          totalBuyAmt += (goods.saleAmt * goods.buyQty);
          totalBuyQty += goods.buyQty;
        });

        let script = `
window.ENP_VAR = { conversion: { product: [] } };
ENP_VAR.conversion.product.push(
${goodsList.join(",\n")}
);
ENP_VAR.conversion.totalPrice = '${totalBuyAmt}';
ENP_VAR.conversion.totalQty = '${totalBuyQty}';
ENP_VAR.conversion.referrer = '${this.getReferrer()}';
ENP_VAR.conversion.referrerDomain = '${this.getReferrerDomain()}';

let params = {
  device: '${this.$device.isMobile ? 'M' : 'W'}', // W:웹, M: 모바일, B: 반응형
  domain: '${this.getSiteDomain()}',
  referrer: '${this.getReferrer()}',
  referrerDomain: '${this.getReferrerDomain()}',
  paySys: 'KCP'
};

(function(a,g,e,n,t){a.enp=a.enp||function(){(a.enp.q=a.enp.q||[]).push(arguments)};n=g.createElement(e);n.async=!0;n.defer=!0;n.src="https://cdn.megadata.co.kr/dist/prod/enp_tracker_self_hosted.min.js";t=g.getElementsByTagName(e)[0];t.parentNode.insertBefore(n,t)})(window,document,"script");
enp('create', 'common', 'lottegfr02', params});
enp('send', 'common', 'lottegfr02');
// console.log("Execute Mobon Page View");

enp('create', 'conversion', 'lottegfr02', {device : '${this.$device.isMobile ? 'M' : 'W'}'} );
// enp('send', 'conversion', 'lottegfr02');
// console.log("Execute Mobon Script - Cart");
        `;
        eval(script);
      } catch (e) {}
    },

    executeCriteo () {
      try{
        //criteo 장바구니페이지 전송
        if (typeof window !== "undefined") {

          let goodsList = [];
          this.cart.goodsList.forEach(goods => {
            goodsList.push({
              id: `${goods.compGoodsId}`,
              price: `${goods.saleAmt}`,
              quantity: `${goods.buyQty}`
            });
          });

          if (goodsList.length > 0) {

            window.criteo_q = window.criteo_q || [];
            var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
            window.criteo_q.push(
              { event: "setAccount", account: 95151},
              { event: "setEmail", email: "" },
              { event: "setZipcode", zipcode: "" },
              { event: "setSiteType", type: deviceType},
              { event: "viewBasket", item: goodsList}
            );
          }
        }
      } catch (e) {
      }

    },
    closeCartModal() {
      this.onOrderStart = false;
      this.$emit("openCartModal");
    },
    openCartModal() {
      this.$emit("openCartModal");
    },
    doRoute(path, query) {
      query = query || {};

      this.$router.push({path: path, query: query});
      this.openCartModal();
      // 400ms 이후 꺼짐
      //setTimeout(() => this.$emit("openCartModal"), 400);
    },
    goCart(){
      this.openCartModal();
      return this.$router.push({
        path: "/cart",
      });
    },

    /**
     * 상품이 체크되었는지 확인
     * @param goods
     * @returns {boolean}
     */
    isChecked(goods) {
      let id = `${goods.goodsId}_${goods.itemNo}`;
      return this.selectedGoodsList.indexOf(id) >= 0;
    },

    /**
     * 상품 체크/체크해제
     * @param goods
     */
    toggleCheck(goods) {
      let id = `${goods.goodsId}_${goods.itemNo}`;
      if (goods.salePsbCd == '30') return;
      if (this.isChecked(goods)) {
        this.selectedGoodsList.splice(this.selectedGoodsList.indexOf(id), 1);
        this.totalCheckCnt -= goods.buyQty;
      } else {
        this.selectedGoodsList.push(id);
        this.totalCheckCnt += goods.buyQty;
      }
      this.updateCartInfo();
    },

    /**
     * 상품 전체 체크/체크해제
     */
    toggleCheckAll() {
      if (this.isAllChecked()) {
        //console.log('ha')
        this.selectedGoodsList = [];
        this.totalCheckCnt=0;
      } else if(this.isAllChecked() === false){
       // this.selectedGoodsList = this.goodsList.filter(x => x.salePsbCd != '30').map((goods) => `${goods.goodsId}_${goods.itemNo}`);
         this.selectedGoodsList = this.goodsList.filter(x => x.salePsbCd != '30').map((goods) => `${goods.goodsId}_${goods.itemNo}`);
        this.getTotalCartCount();
      }
      this.updateCartInfo();
    },
    isAllChecked() {
      return (
        this.goodsList.length > 0 &&
        this.goodsList.filter(x => x.salePsbCd != '30').length === this.selectedGoodsList.length
      );
    },
    getTotalCartCount() {
     this.totalCheckCnt =  this.goodsList.reduce((acc,cur,i) => {
       //console.log(cur.buyQty)
        return acc + cur.buyQty},0);
    },

    /**
     * 장바구니에서 상품 삭제
     */
    deleteSelectedGoodsFromCart() {
      if (this.selectedGoodsList.length === 0) {
        this.$modal.warning("삭제할 상품을 선택해주세요.");
      } else {
        this.$modal
          .confirm("선택한 상품을 삭제하시겠습니까?")
          .then(async () => {
            try {
              this.$nuxt.$loading.start();
              let works = this.goodsList
                .filter((x) => this.selectedGoodsList.indexOf(`${x.goodsId}_${x.itemNo}`) >= 0)
                .map((x) => this.$cart.remove(x));
              await Promise.all(works);
                this.afterUpdate();
                this.$modal.success("삭제되었습니다.")
                 .then(() =>{

               // this.goodsList = cart.goodsList //새로고침 없이 데이터 변경
                 }
                 );
            } catch (e) {
              // console.error(e);
              this.$modal.error("삭제하는데 실패하였습니다.");
            } finally {
              this.$nuxt.$loading.finish();
            }
          });
      }
    },

    /**
     * 위시리스트 추가
     * @param goods
     * @returns {Promise<void>}
     */
    async addWishList(){
      if (this.selectedGoodsList.length === 0 ){
        this.$modal.warning("위시리스트에 추가할 상품을 선택해 주세요.");
      }else {
        this.$modal.confirm("선택한 상품을 위시리스트에 추가하시겠습니까?")
          .then( async () => {
            let works = this.goodsList.filter((x) => this.selectedGoodsList.indexOf(`${x.goodsId}_${x.itemNo}`) >= 0)
              .map((x) => this.$cart.addWishList(x));
            await Promise.all(works);
            this.$modal.success("추가되었습니다.")
              .then(() => this.$router.go(0) );
          })
      }
      // await this.$axios.$post('/api/category/saveMbrIntrGoods',goods);
      // await this.getWishList()
    },
    async getWishList() {
      this.wishList = await this.$axios.$get('/api/main/wish-list/ids');
    },

    /**
     * 품절 상품 삭제
     */
    deleteSoldOutGoodsFromCart() {
      let soldOutList = this.goodsList.filter((goods) => goods.salePsbCd === "30");
      if (soldOutList.length === 0) {
        this.$modal.warning("품절된 상품이 없습니다.");
      } else {
        this.$modal
          .confirm("품절된 상품을 장바구니에서 삭제하시겠습니까?")
          .then(async () => {
            try {
              this.$nuxt.$loading.start();
              let works = soldOutList.map((x) => this.$cart.remove(x));
              await Promise.all(works);
                this.afterUpdate();
                this.$modal.success("삭제되었습니다.")
                //.then(() => this.$router.go(0) );
            } catch (e) {
              // console.error(e);
              this.$modal.error("삭제하는데 실패하였습니다.");
            } finally {
              this.$nuxt.$loading.finish();
            }
          });
      }
    },

    /**
     * 주문 버튼 클릭
     * @returns {Promise<*|undefined>}
     */
    async onSubmit(type) {
      //console.log('오더boolean',this.onOrderStart)
      this.isOrderOnProcess = this.onOrderStart
      //await this.validateAgreementCheck();

      if (this.selectedGoodsList.length === 0) {
        this.$modal.error("주문할 상품이 없습니다.");
      } else if (this.isOrderOnProcess) {
        // Do nothing, but process already started
        // ignore it
      } else {
        //console.log('진행')
        this.isOrderOnProcess = true;
        let goodsList = this.goodsList.filter((x) => this.selectedGoodsList.indexOf(`${x.goodsId}_${x.itemNo}`) >= 0);
        //console.log('오더리스트',goodsList)
        this.orderGoodsList(goodsList, type);
      }
    },

    /**
     * 상품 주문 Continue...
     * @param goodsList
     * @returns {Promise<Route>}
     */
    async orderGoodsList(goodsList, type) {
      try {
        let response = await this.$axios.post("/api/order-list", goodsList);
        let orderId = (response || {}).headers["x-order-id"];
        if (orderId) {
          // sessionStorage.setItem(this.$env.ORDER_STORAGE_KEY, orderId);
          this.$cookies.set(this.$env.ORDER_STORAGE_KEY, orderId);

          if(type === 1) {
            if (this.$store.state.session.isGuest) {
              this.openCartModal()
              return this.$router.push({
                path: "/login",
                query: { referrer: "order" },
              });
            } else {
              this.openCartModal()
              return this.$router.push({ path: "/order" });

            }
          }else{
            if (this.$store.state.session.isGuest) {
              this.openCartModal()
              return this.$router.push({
                path: "/login",
                query: { referrer: "gift" },
              });
            } else {
              this.openCartModal()
              return this.$router.push({ path: "/gift" });
            }
          }
        }
      } catch (e) {
        console.log("error ;::: ",e);
        let data = e.response.data;
        if (data.length > 0) {
          let messages = data.map(x => {
            let goods = goodsList.filter(y => y.goodsId == x.goodsId && y.itemNo == x.itemNo)[0];
            return `[${goods.goodsNm} - ${goods.itemNm}] : ${x.message}`;
          }).join("<br/>");
          this.$modal.error(messages);
        }
      }
    },

    /**
     * 상품 수량 증가
     * @param goods
     * @returns {Promise<void>}
     */
    async addCartBuyQty(goods) {
      if (this.onQtyChange) return;
      this.onQtyChange = true;
      let buyQty = goods.buyQty + 1;
      await this.$cart.add(Object.assign({}, goods, { buyQty }));
      return this.$router.go(0);
    },

    /**
     * 상품 수량 감소
     * @param goods
     * @returns {Promise<void>}
     */
    async subtractCartBuyQty(goods) {
      if (this.onQtyChange) return;
      this.onQtyChange = true;
      let buyQty = goods.buyQty - 1;
      if (buyQty > 0) {
        await this.$cart.add(Object.assign({}, goods, { buyQty }));
        return this.$router.go(0);
      } else {
        this.$modal.error("최소 구매수량은 1개 입니다.");
      }
    },

    async fetchCart() {
      await this.$cart.get();
      return this.updateGoodsList();
    },

    /**
     * 장바구니 목록 갱신 이벤트 핸들러
     */
    updateGoodsList() {
      this.goodsList = this.$cart.goodsList;
      this.selectedGoodsList = this.goodsList.filter(x => x.salePsbCd != '30').map((goods) => `${goods.goodsId}_${goods.itemNo}`);
      this.updateCartInfo();
    },

    /**
     * 장바구니 결제 금액/배송 금액 업데이트
     * @returns {Promise<void>}
     */
    async updateCartInfo() {
      await this.$cart.updateCartInfo(this.selectedGoodsList);
      this.totalGoodsAmt = this.$cart.totalGoodsAmt;
      this.totalPrmtDcAmt = this.$cart.totalPrmtDcAmt;
      this.totalOfrstfDcAmt = this.$cart.totalOfrstfDcAmt;
      this.totalDlvrAmt = this.$cart.totalDlvrAmt;
      this.onQtyChange = false;
    },

    /**
     * 옵션 모달
     */
    async openOptionModal(goods) {
      let OptionModal = this.$device.isMobile ? MobileCartOption : WebOptionModal;
      let map = await this.$axios.$get("/api/goods-option", { params: { goodsId: goods.goodsId } });
      console.log("map ::: ", map);
      if (map.types.length > 1) {
        this.$modal.show(
          OptionModal,
          {
            goods,
            types: map.types,
            items: map.items,
            update: async ({ itemNo, buyQty }) => {
              if (goods.itemNo != itemNo || buyQty != goods.buyQty) {
                await this.$cart.replace({
                  goodsId: goods.goodsId,
                  itemNo: goods.itemNo
                }, {
                  goodsId: goods.goodsId,
                  itemNo,
                  utmSource: goods.utmSource,
                  exhbtNo: goods.exhbtNo,
                  inflowMethod: goods.inflowMethod,
                  buyQty
                });
                let id =  window.localStorage.getItem(this.STORAGE_KEY);
                let cart = await this.$axios.$get("/api/cart", {params:{id}});
               this.goodsList = cart.goodsList //새로고침 없이 데이터 변경
               this.selectedGoodsList = this.goodsList.filter(x => x.salePsbCd != '30').map((goods) => `${goods.goodsId}_${goods.itemNo}`);
               this.totalGoodsAmt = cart.totalGoodsAmt
               this.$cart.afterFetch(cart)
               //this.updateCartInfo();
               //console.log('after',this.goodsList)
                //return this.$router.go(0);
                //현재 itemNo가 다름
              }
            }
          },
          {
            width: "400px",
            height: "auto",
          }
        );
      } else {
        this.$modal.error("선택할 수 있는 옵션이 없습니다.");
      }
    },

    /**
     * 상품 주문 (단건)
     * @param goods
     * @returns {Promise<Route>}
     */
    orderGoods(goods) {
      goods = Object.assign(goods);
      goods.nowBuyYn = "Y";

      return this.orderGoodsList([goods],1);

    },

    /**
     * 장바구니 상품 삭제 (단품)
     * @param goods
     */
    removeGoods(goods) {
      this.$modal
        .confirm("장바구니 상품을 삭제하시겠습니까?")
        .then(async () => {
          try {
            //로딩,
            await this.$cart.remove(goods);
              let id =  window.localStorage.getItem(this.STORAGE_KEY);
                let cart =  await this.$axios.$get("/api/cart", {params:{id}});
                this.totalGoodsAmt = cart.totalGoodsAmt
                //console.log('cc',cart)
                this.goodsList = cart.goodsList;
                 this.selectedGoodsList = this.goodsList.filter(x => x.salePsbCd != '30').map((goods) => `${goods.goodsId}_${goods.itemNo}`);
                //this.$modal.success("삭제되었습니다.")
            this.$modal.success("삭제되었습니다.").then(() => {
              this.afterUpdate(); //여기에 갯수 카운트
             // this.openCartModal()
             // this.$router.go(0);
            });
          } catch (e) {
            // console.error(e);
          }
        });
    },

    openCardEventModal(event) {
      this.$modal.show(
        HtmlModal, {
          html: event.content
        }, {
          width: this.$device.isMobile ? "100%" : "600px",
          height: this.$device.isMobile ? "100%" : "auto",
        }
      );
    },

    async fetchRecommendGoodsList() {
      /*
      let params = {
        "st_id": this.$env.siteNo,
        "type": this.$device.isMobile ? "MO" : "PC",
        "collection": "GOODS",
        "displaysort": "ESTM_SCORE_DESC",
        "docPage": 5
      };

      let { goodsPage } = await this.$axios.$post("/diquest/search", params);
      this.recommGoodsList = goodsPage.contents;
      */
      try {
        let recommendGoodsList = await this.$axios.$get("/api/cart/best", {});
        this.recommGoodsList = recommendGoodsList;
      } catch (e) {}
    },

    saleAmt(goods) {
      if (goods.orgAmtHiddenYn != "Y") {
        return numeral(goods.orgSaleAmt).value();
      } else {
        return numeral(goods.saleAmt).value();
      }
    },

    offDcAmt(goods) {
      let orgSaleAmt = numeral(goods.orgSaleAmt).value();
      let saleAmt = numeral(goods.saleAmt).value();
      if (goods.orgAmtHiddenYn != "Y") {
        return orgSaleAmt - saleAmt;
      } else {
        return 0;
      }
    },

    saleAmtPercent(goods) {
      let saleAmt = goods.saleAmt;
      let prmtDcAmt = goods.prmtDcAmt;
      let orgSaleAmt = goods.orgPrice;

      if (goods.orgAmtHiddenYn === 'Y') {
        return prmtDcAmt / saleAmt;
      } else {
        return (orgSaleAmt - (saleAmt - prmtDcAmt)) / orgSaleAmt;
      }
    },

    formatPercentGoods(value, mode) {

      if (mode) {
        switch (mode) {
          case "ceil" :
            value = Math.ceil(value * 100) / 100;
            break;
          case "floor" :
            value = Math.floor(value * 100) / 100;
            break;
          default:
        }
      }

      return numeral(value).format("0%");
    },


    beforeDiscountOrgAmt(goods) {
      let saleAmt = goods.saleAmt;
      let orgSaleAmt = goods.orgPrice;

      if (goods.orgAmtHiddenYn === 'Y') {
        return saleAmt;
      } else {
        return orgSaleAmt;
      }
    },
    async afterUpdate(){
        let id =  window.localStorage.getItem(this.STORAGE_KEY);
        let cart =  await this.$axios.$get("/api/cart", {params:{id}});
        this.totalGoodsAmt = cart.totalGoodsAmt
        this.goodsList = cart.goodsList;
        this.selectedGoodsList = this.goodsList.filter(x => x.salePsbCd != '30').map((goods) => `${goods.goodsId}_${goods.itemNo}`);
        this.$store.commit("session/updateCartGoodsLength", this.goodsList.length); //장바구니 개수 업데이트
    },
    dcAmt(goods) {
      let prmtDcAmt = numeral(goods.prmtDcAmt).value();
      return this.offDcAmt(goods) + prmtDcAmt;
    },
    genderStr(goods) {
      if (goods.gndrKd == "M") {
        return "MEN";
      } else if (goods.gndrKd == "F") {
        return "WOMEN";
      } else if (goods.gndrKd == "K") {
        return "KIDS"
      } else if (goods.gndrKd == "Z") {
        return "UNISEX";
      } else {
        return "";
      }
    },

  }
}
</script>
