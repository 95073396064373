<template>
  <div class="popup_layer option">
    <div class="popup_header">
      <button type="button" class="bt_closepop" @click.stop="$emit('close')">닫기</button>
    </div>
    <div class="popup_contents item_optionli">
      <!-- 옵션선택 -->
      <div class="option_wrap">
        <div class="option_inner" v-if="itemOptions['6'].length > 0">
          <!-- 컬러 -->
          <h4 class="title_option">COLOR : <span class="txt_option_color">{{ (selectItemOption['6'] || {}).attrVal || cartColor }}</span></h4>
          <div class="rad_colorwrap">
            <label
              class="rad_color"
              v-for="(option, index) in itemOptions['6']"
              :key="`6_${index}`"
              :class="{ 'checked': colorCheck === option.attrVal, 'soldout': option.stockQty == 0 }"
            >
              <input
                type="radio"
                v-model="selectItemOption['6']"
                :value="option"
                :disabled="option.stockQty == 0"
                @change="onAttrSelected('6'); resetBuyQty();"
                ref="checkRef"
              >

              <span :style="{ 'background-color': option.rgbVal }" :class="{ soldout : option.stockQty == 0 }" v-if="['#ZZZZZZ'].indexOf(option.rgbVal) < 0 && ['Multi','MultiColor'].indexOf(option.attrVal) < 0 ">{{ option.attrVal }}</span>
              <span class="multi" :class="{ soldout : option.stockQty == 0 }" v-else>{{ option.attrVal }}</span>

              <!--<span :style="{ 'background-color': option.rgbVal }">
                {{ option.attrVal }}
              </span>-->

            </label>
          </div>
        </div>
        <div class="option_inner" v-if="itemOptions['7'].length > 0">
          <!-- 사이즈 -->
          <h4 class="title_option">SIZE</h4>
          <div class="circle_rad">
            <div>
              <label
                class="rad_size"
                v-for="(option, index) in itemOptions['7']"
                :key="`7_${index}`"
                :class="{ 'checked': sizeCheck === option, 'soldout': option.stockQty == 0 }"
              >
                <input
                  type="radio"
                  v-model="selectItemOption['7']"
                  :value="option"
                  :disabled="option.stockQty == 0"
                  @change="onAttrSelected('7'); resetBuyQty();"
                >
                <span :class="{ soldout : option.stockQty == 0 }">{{ option.attrVal }}</span>
              </label>
              <span class="txt_option_color" v-if="hasCancelButton && addSaleAmt > 0">+{{ formatAmount(addSaleAmt) }}</span>
            </div>
          </div>
        </div>
        <div class="option_inner">
          <!-- 수량 -->
          <div class="option_quantity">
            <button type="button" class="minus" @click.stop="subQty()">
              -
            </button>
            <input type="number" class="quantity" v-model="buyQty" min="1" readonly="readonly">
            <button type="button" class="plus" @click.stop="addQty()">
              +
            </button>
          </div>
        </div>
      </div>
      <!--// 옵션선택 -->
    </div>
    <div class="popup_footer">
      <div class="bt_fix option_fixbtn">
        <button type="button" class="bt_black gift" @click.stop="onSubmit(buyQty, '30')" v-if="hasCancelButton"><span>선물하기</span></button>
        <button type="button" class="bt_black enp_mobon_cart" @click.stop="onSubmit(buyQty, '10')" v-if="hasCancelButton">CART</button>
        <button type="button" class="bt_black buynow" @click.stop="onSubmit(buyQty, '20')" v-if="hasCancelButton">BUY NOW</button>
        <button type="button" class="bt_black" @click.stop="onSubmit(buyQty)" v-if="!hasCancelButton">변경</button>
      </div>
      <!--
      <div class="bt_1st detail_gift_w" v-if="this.giftYn == 1">
        <button type="button" class="bt_black" @click.stop="onSubmit(buyQty, '30')" v-if="hasCancelButton"><span>선물하기</span></button>
        <button type="button" class="bt_black" @click.stop="onSubmit(buyQty)" v-if="!hasCancelButton">변경</button>
      </div>-->
    </div>
  </div>
</template>

<script>
import WebOptionModal from "@/components/pages/cart/modal/WebOptionModal";

export default {
  extends: WebOptionModal,

  data: () => ({
    buyQty: 0,
    colorCheck: undefined,
    sizeCheck: undefined,
    attrCheck: false,
    indx:0,
    cartColor: undefined,
  }),

  watch: {
    goods: {
      immediate: true,
      handler(value) {
        this.buyQty = (value || {}).buyQty || 0;
      }
    }
  },
  mounted() {
    try {
          if(this.$device.isMobile) {

            eval(`
                (function(a,g,e,n,t){a.enp=a.enp||function(){(a.enp.q=a.enp.q||[]).push(arguments)};n=g.createElement(e);n.async=!0;n.defer=!0;n.src="https://cdn.megadata.co.kr/dist/prod/enp_tracker_self_hosted.min.js";t=g.getElementsByTagName(e)[0];t.parentNode.insertBefore(n,t)})(window,document,"script");
                enp('create', 'cart', 'lottegfr02', {device : 'M', btnSelector : '.enp_mobon_cart' } );
                          `);
          }
        } catch (e) {
          // console.error(e);
        }

// this.selectItemOption['7'] = this.slctSize
 if(this.selectItemOption['6'] !== undefined){
 this.colorCheck = this.selectItemOption['6'].attrVal;
 this.attrCheck = true //buy now 전에 옵션 체크 확인
 }
// if(this.selectItemOption['7'] !== undefined){
// this.sizeCheck = this.selectItemOption['7'].attrVal;
// }
  },

  methods: {
    addQty() {
      let buyQty = this.buyQty + 1;
     if (this.goods.maxOrdQty != null && this.goods.maxOrdQty < buyQty) {
        buyQty = this.buyQty;
        this.$modal.error('해당 상품의 최대 주문수량은 [' + this.goods.maxOrdQty + '] 입니다.');
      }
      let items = this.getFilteredItems();
      items = items.filter(x => x.stockQty >= 1);
        
      if(items.length == 1 && items[0].stockQty < buyQty){
         buyQty = this.buyQty;
         this.$modal.error('해당 상품의 최대 주문수량은 [' + items[0].stockQty + '] 입니다.');
       }
      this.buyQty = buyQty;
    },
     findIdx(i){
      if(this.selectItemOption['6'] !== undefined && this.attrCheck === true){

        if(this.itemOptions['6'][i].attrVal === this.scolor.attrVal){
        this.indx = i;
        //console.log(this.indx)
        this.$refs['checkRef'][this.indx].click()
        }
       }
      },
    subQty() {
      let buyQty = this.buyQty - 1;
      if (buyQty < 1) {
        buyQty = 1;
        this.$modal.error('하나 보다 적게 선택할 수 없습니다.');
      }
      this.buyQty = buyQty;
    },
    resetBuyQty() {
      this.buyQty = 1;
    }
  }
}
</script>

<style>
.popup_layer.option .option_quantity {
  margin-bottom : 25px;
}

</style>
