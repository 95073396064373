import _ from "@/assets/libs/lodash";
import moment from "moment";

export default class Creme {
  /**
   * Construct creme
   * @param axios {NuxtAxiosInstance} Axios
   * @param key {string} Storage key
   */
  constructor(axios, key) {
    this.axios = axios;
    this.CREMA_TOKEN = key;

    // console.log(this.CREMA_TOKEN)
  }

  async userUpdate(msg) {
    console.log("Crema userUpdate:", msg)
    let profile = await this.axios.$get("/api/user/me");
        // this.$store.commit("session/set", profile);
    if (profile.name != "GUEST") {
      const creParams = {
        access_token: this.CREMA_TOKEN,
        user_id: profile.loginId,
        user_name: profile.name,
        allow_sms: 0,
        allow_email: 0
      }
      let form = [
        `access_token=${this.CREMA_TOKEN}`,
        `user_id=${profile.loginId}`,
        `user_name=${profile.name}`,
        `allow_sms=0`,
        `allow_email=0`
      ].join("&");
      // console.log(creParams)
      try{
        // const creResonse = await this.axios.$post(`/crema_api/v1/users`, form, {
        //   headers: {
        //     'Content-Type': 'application/x-www-form-urlencoded'
        //   }
        // });
        window.cremaAsyncInit = function () {
          crema.init(`${profile.loginId}`, `${profile.name}`);
        };
        window.cremaAsyncInit();
      } catch (e) {
        console.log("cremaAsyncInitError", e)
      }
      // console.log("creResonse", creResonse)
    }
    // await this.axios.$post("/api/cart", data);
  }

  async get() {

  }

  /**
   * 주문 추가
   * @param order
   * @returns {Promise<void>}
   */
  // async orderInsert(order){
  //   console.log("crema OrderInsert ::: ", order);
  //
  //   let profile = await this.axios.$get("/api/user/me");
  //
  //   let now = moment().format("YYYY-MM-DD HH:mm:ss");
  //   if (profile.name != "GUEST") {
  //     let crmOrder = [
  //       `access_token=${this.CREMA_TOKEN}`,
  //       `code=${order.ordNo}`,
  //       `total_price=${order.payAmt}`,
  //       `user_code=${order.email}`,
  //       `user_name=${order.name}`,
  //       `user_email=${order.email}`,
  //       `user_phone=${order.mobile}`,
  //       `created_at=${now}`
  //     ].join("&");
  //
  //
  //     try{
  //       const creResonse = await this.axios.$post(`/crema_api/v1/orders`,crmOrder, {
  //         headers: {
  //           'Content-Type': 'application/x-www-form-urlencoded'
  //         }
  //       }).then((response) => {
  //         if(order.goodsList.length >= 0 ){
  //           let subOrder
  //
  //           let param = {
  //             orderNo: order.ordNo,
  //             orderId: response.id,
  //             resultCode: response.code || undefined,
  //             resultMsg: response.message || undefined,
  //           }
  //
  //           if(order.goodsList.length >= 1 ) {
  //             this.axios.$post('/api/my-page/crema/order', param);
  //             order.goodsList.forEach((x,idx) => {
  //               let colorSize = x.itemNm.split('/')
  //               subOrder = [
  //                 `access_token=${this.CREMA_TOKEN}`,
  //                 `product_code=${x.goodsId}`,
  //                 `order_id=${order.ordNo}`,
  //                 `price=${x.saleAmt}`,
  //                 `status=paid`,
  //                 `code=${order.ordNo}_${idx+1}`,
  //                 `product_options[]=color:${colorSize[0]}`,
  //                 `product_options[]=size:${colorSize[1]}`
  //               ].join("&")
  //
  //
  //               try {
  //                 this.axios.$post(`/crema_api/v1/orders/${response.id}/sub_orders`, subOrder, {
  //                   headers: {
  //                     'Content-Type': 'application/x-www-form-urlencoded'
  //                   }
  //                 })
  //               }catch (e){
  //                 console.log("subOrderError ", e)
  //               }
  //             })
  //           }
  //         }
  //       })
  //
  //     } catch (e) {
  //       console.log("creResonseError", e)
  //     }
  //   }
  // }

  /*
  setGoodsList(goodsList) {
    this.goodsList = goodsList || [];
  }

  async get() {
    let id = undefined;
    try {
      id = window.localStorage.getItem(this.STORAGE_KEY);
    } catch (e) {
      id = undefined;
    }
    try {
      let cart = await this.axios.$get("/api/cart", { params: { id } });
      this.afterFetch(cart);
    } catch (e) {
      // console.error(e);
      this.id = undefined;
      this.goodsList = [];
    }
  }

  / **
   * 상품 추가
   * @param item {Object} 추가할 단품
   * /
  async add(item) {
    let data = _.merge(
      {
        cartId: this.id,
        goodsId: undefined,
        itemNo: undefined,
        buyQty: 0,
      },
      item || {}
    );

    if (
      data.goodsId !== undefined &&
      data.itemNo !== undefined &&
      data.buyQty > 0
    ) {
      await this.axios.$post("/api/cart", data);
    } else {
      // console.error("Invalid item", item);
    }
  }

  / **
   * 단품 제거
   * @param item {Object} 삭제할 단품
   * /
  async remove(item) {
    item = item || {};

    if (item.goodsId !== undefined && item.itemNo !== undefined) {
      await this.axios.$delete("/api/cart", {
        params: {
          cartId: this.id,
          goodsId: item.goodsId,
          itemNo: item.itemNo,
        },
      });
    }
  }

  / **
   * 데이터 Fetching 후
   * @param cart {object} 장바구니 Response Body
   * /
  afterFetch(cart) {
    this.id = (cart || {}).id;
    this.goodsList = (cart || {}).goodsList;

    this.store.commit("session/updateCartGoodsLength", this.goodsList.length);
    _putCartId(
      (cart || {}).id,
      this.store.state.session.isGuest,
      this.STORAGE_KEY
    );
  }

  / **
   * 상품 변경
   * @param from {Object} 변경될 단품
   * @param to {Object} 변경할 단품
   * /
  async replace(from, to) {
    await Promise.all([
      this.remove(from),
      this.add(to)
    ]);
  }

  / **
   * 장바구니 결제 정보 갱신
   * @param select {array} 선택된 장바구니 결제 정보
   * /
  async updateCartInfo(select) {
    select = select || [];
    this.totalGoodsAmt = 0;
    this.totalPrmtDcAmt = 0;
    this.totalOfrstfDcAmt = 0;
    this.totalDlvrAmt = 0;
    this.totalAmt = 0;
    if (select.length > 0) {
      let data = this.goodsList.filter(
        (x) => select.indexOf(`${x.goodsId}_${x.itemNo}`) >= 0
      );
      if (data.length > 0) {
        let cart = await this.axios.$put("/api/cart/cart-total-info", data);
        this.totalGoodsAmt = (cart || {}).totalGoodsAmt || 0;
        this.totalPrmtDcAmt = (cart || {}).totalPrmtDcAmt || 0;
        this.totalOfrstfDcAmt = (cart || {}).totalOfrstfDcAmt || 0;
        this.totalDlvrAmt = (cart || {}).totalDlvrAmt || 0;
        this.totalAmt = (cart || {}).totalAmt || 0;
      }
    }
  }


  clear() {
    _removeStorage(this.STORAGE_KEY);
  } */
}
