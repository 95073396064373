export default {
  state: () => (
    {
      sizeSort: [],
      isEmpty: true
    }
  ),

  mutations: {
    set(state, sizeSort) {
      state.sizeSort = sizeSort;
      state.isEmpty = false;
    },

    clear(state) {
      state.sizeSort = [];
      state.isEmpty = true;
    },
  }
}