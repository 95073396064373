class SsoLib {
  constructor(ssoClientInitInfo, store, $device, $env) {
    this.commonOptions = {
      ccoSiteNo: ssoClientInitInfo.ccoSiteNo,        // 제휴사사이트번호
      clntAkInf: ssoClientInitInfo.clntAkInf,        // 클라이언트요청정보
      vrblNm: 'sso',                                 // 라이브러리 변수명
      urEvnmtDc: $device.isMobile ? store.state.layout.isApp ? '2' : '1' : '0',       // 사용자환경구분코드 0: PC Web 1: Mobile Web 2: Mobile App
    }
    this.$env = $env
  }

  initSsoJoin(options = {}) {
    const defaultOptions = Object.assign(this.commonOptions, {
      srnOpt : {
        opMd : '0'                   // 오픈모드 0: Redirect 1: iframe 2: Popup
      }
    })

    return new SsoClientLibrary(Object.assign(defaultOptions, options));
  }

  initSsoLogin(options = {}) {
    const defaultOptions = Object.assign(this.commonOptions, {
      srnOpt : {
        opMd : '0', // 오픈모드 0: Redirect 1: iframe 2: Popup
      }         
    })

    return new SsoClientLibrary(Object.assign(defaultOptions, options));
  }

  initFindPassword(options = {}) {
    const defaultOptions = Object.assign(this.commonOptions, {
      srnOpt : {
        opMd : '0', // 오픈모드 0: Redirect 1: iframe 2: Popup
      }         
    })

    return new SsoClientLibrary(Object.assign(defaultOptions, options));
  }


  /**
   * acesTkn required 
   */
  initSsoLogout(options = {}) {
    const defaultOptions = Object.assign(this.commonOptions, {
      acesTkn: undefined,               // 접근토큰
      srnOpt : {
        opMd : '0',                      // 오픈모드
      } 
    })

    return new SsoClientLibrary(Object.assign(defaultOptions, options));
  }
}

export default async ({ store, $axios, $device, $env }, inject) => {
  let ssoClientInitInfo = {
    ccoSiteNo: null,
    clntAkInf: null
  }

  try {
    ssoClientInitInfo = await $axios.$get('/api/lmembers/sso/clientInit');
  } catch (error) {}

  let ssoLib = new SsoLib(ssoClientInitInfo, store ,$device, $env)
  
  inject("ssoLib", ssoLib);
};