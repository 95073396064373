<template>
  <div class="popup_layer" :class="{ desktop: $device.isDesktop, mobile: !$device.isDesktop }">
    <div class="close_fixed">
      <h5><span class="txt_blue strong popup_title">L.POINT 통합 회원 혜택</span></h5>
      <div class="flex low between popup_close">
        <button type="button" class="bt_closepop" @click.stop="$emit('close')">닫기</button>
      </div>
    </div>
    <div class="coments">
      <div class="popup_box">
        <table class="popup_table">
          <thead>
            <tr style="border-top: none">
              <th colspan="3"><h5 class="title_table_pop">구매시 구매 포인트 지급</h5></th>
            </tr>
            <tr class="f14">
              <th>등급</th>
              <th>나이스클랍, 까웨, 카파</th>
              <th>캐나다구스</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>FAMILY</td>
              <td>1% 지급</td>
              <td rowspan="4">5% 지급</td>
            </tr>
            <tr>
              <td>GOLD</td>
              <td>1.5% 지급</td>
            </tr>
            <tr>
              <td>VIP</td>
              <td>2% 지급</td>
            </tr>
            <tr>
              <td>MVG</td>
              <td>3% 지급</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="popup_box">
        <table class="popup_table">
          <thead>
            <tr style="border-top: none">
              <th colspan="3"><h5 class="title_table_pop">생일자 쿠폰 및 추가 적립</h5></th>
            </tr>
            <tr class="f14">
              <th>등급</th>
              <th>나이스클랍, 까웨, 카파</th>
              <th>캐나다구스</th>
            </tr>
          </thead>
          <tbody class="td_color">
            <tr class="td_color">
              <td>FAMILY, GOLD</td>
              <td>10% 할인쿠폰</td>
              <td>-</td>
            </tr>
            <tr class="td_color">
              <td>VIP, MVG</td>
              <td>15% 할인쿠폰</td>
              <td>-</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="popup_box">
        <table class="popup_table">
          <thead>
            <tr style="border-top: none">
              <th colspan="3"><h5 class="title_table_pop">등급생성 혜택</h5></th>
            </tr>
            <tr class="f14">
              <th>등급</th>
              <th>나이스클랍, 까웨, 카파</th>
              <th>캐나다구스</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>FAMILY, GOLD</td>
              <td>10% 할인쿠폰</td>
              <td>-</td>
            </tr>
            <tr>
              <td>VIP, MVG</td>
              <td>15% 할인쿠폰</td>
              <td>-</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <ul class="li_dot">
          <li>할인혜택에 중복은 불가합니다.</li>
          <li>L.POINT는 롯데GFR 및 L.POINT 제휴사에서 상품구매 또는 이용 후 적립 받으실 수 있는 포인트입니다.</li>
          <li>롯데GFR L.POINT 통합 회원이 되시면 롯데GFR 및 L.POINT 제휴사 어디서나 L.POINT를 적립/사용 하실 수 있습니다.</li>
          <li>L.POINT 사용은 10포인트 부터 가능합니다.</li>
        </ul>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    contents: {
      type: String,
      default: "",
    },
  },

  methods: {
    onOkClicked() {
      this.$attrs.resolve();
      this.$emit("close");
    },
    onCancelClicked() {
      this.$attrs.reject();
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.popup_layer {
  width: inherit;
  position: fixed;
  background-color: #fff;
  z-index: 400;
  transform: translate(-50%,-50%);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.popup_layer.desktop {
  width: 750px;
  height: 85vh;
  padding: 50px;
}
.popup_layer.mobile {
  left: 50%;
  top: 50%;
  width: 95vw;
  height: 80vh;
  padding: 50px 20px;
}
.popup_layer .popup_title { 
  float: left;
  font-size: 16px;
  margin-bottom: 20px;
}
.popup_layer .popup_box ~ .popup_box .popup_table {
  margin-top: 50px;
}
.popup_layer .popup_box tr th,td {
  padding: 15px 0;
}
.popup_layer .popup_box tr:first-child th {
  padding: 0;
  padding-bottom: 15px;
}
.desktop .bt_closepop {
  width: 24px;
  height: 24px;
  position: unset;
  font-size: 0;
  background: transparent url("/images/bt_close2.png") no-repeat 50% 50%;
  background-size: 12px auto;
}
.desktop .popup_contents {
  padding-bottom: 20px;
}
.desktop .popup_centents .terms_con {
  border-top: 1px solid #e1e1e1;
  padding: 20px 20px 0 0;
}
.desktop .popup_contents .terms_con p {
  color: #727272;
  word-break: keep-all;
}
.desktop .bt_popup {
  width: unset;
  flex-grow: 1;
  flex-shrink: 1;
  -webkit-box-flex: 1;
}
.desktop .bt_popup ~ .bt_popup {
  margin-left: 5px;
}
.desktop .coments{
  overflow: auto;
  height: 100%;
 }
.desktop .coments::-webkit-scrollbar {
  width: 10px;
}
.desktop .coments::-webkit-scrollbar-thumb {
  border-radius: 1em;
  background-color: transparent;
  background-clip: content-box;
  border: 2px solid transparent;
}
.desktop .coments:hover::-webkit-scrollbar-thumb {
  background-color: #e1e1e1;
}
.mobile .bt_closepop {
  width: 24px;
  height: 24px;
  position: unset;
  font-size: 0;
  background: transparent url("/images/bt_close2.png") no-repeat 50% 50%;
  background-size: 12px auto;
}
.mobile .popup_contents {
  padding-bottom: 20px;
}
.mobile .popup_contents .terms_con {
  border-top: 1px solid #e1e1e1;
  padding: 20px 20px 0 0;
}
.mobile .popup_contents .terms_con p {
  color: #727272;
  word-break: keep-all;
}
.mobile .bt_popup {
  width: unset;
  flex-grow: 1;
  flex-shrink: 1;
  -webkit-box-flex: 1;
}
.mobile .bt_popup ~ .bt_popup {
  margin-left: 5px;
}
.mobile .coments{
  overflow: auto;
  height: 100%;
  word-break: keep-all;
}
</style>