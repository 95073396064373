<template>
  <div>
    <header class="header_wrap" :class="{ 'fixed':isMoveScroll}">
      <!-- 스크롤 시 .fixed 추가하시면 됩니다 -->
      <!-- s: 띠배너 추가 -->
      <!-- <div class="top_bnslide"> -->
      <div class="top_bnslide" :class="{'dp_none': $store.getters['scroll-direction/isScrollDown']}">
        <!-- Swiper -->
        <client-only>
          <swiper :options="swiperOptions">
            <swiper-slide
              v-for="(banner, index) in textBannerRollingList"
              :key="`banner_${index}`"
            >
              <a
                :href="getUrl(banner.bnrLinkUrl)"
              >
                {{ banner.bnrHtml }}
              </a>
            </swiper-slide>
          </swiper>
        </client-only>
        <!-- //Swiper -->
        <!-- <swiper :space-between="30" :centered-slides="true" :pagination="{clickable: true,}" :modules="modules" :navigation="true" :loop="true" :autoplay="{delay: 2000,disableOnInteraction: false,}" class="mySwiper">
          <div class="swiper-slide">
            <a href="/join">회원가입시 바로 사용할 수 있는 20% 쿠폰을 발급해드립니다.</a>
          </div>
          <div class="swiper-slide">
            <a href="/join">회원가입시 바로 사용할 수 있는 20% 쿠폰을 발급해드립니다.</a>
          </div>
        </swiper>
        <div class="swiper-button-prev top_bnprev"></div>
        <div class="swiper-button-next top_bnnext"></div> -->
      </div>

      <!-- e: 띠배너 추가 -->
      <nav class="header_gnb">
        <div class="header_left">
          <a class="bt_back" href="javascript:;" @click.prevent="backButtonClicked()" v-if="isShowHistoryBack">
            이전 화면으로
          </a>
          <a href="javascript:;" class="bt_menu" @click.prevent="openCategoryModal()">메뉴</a>
        </div>
        <div class="header_center">
          <h1 class="logo" v-if="chkRoute"><nuxt-link to="/" class="bt_logo">K-WAY</nuxt-link></h1>
          <h1 class="title" v-else>{{ headerTitle }}</h1>
        </div>
        <div class="header_right">
          <!--          <nuxt-link :to="{path:'/cart'}" class="bt_cart">-->
          <!--            장바구니-->
          <!--            <span class="cart_badge" v-if="$store.state.session.profile.cartGoodsCount > 0">-->
          <!--              {{ $store.state.session.profile.cartGoodsCount }}-->
          <!--            </span>-->
          <!--          </nuxt-link>-->
          <a
            class="bt_cart"
            href="javascript:;"
            @click.prevent="openCartModal()"
          >
            장바구니
            <span
              class="cart_badge"
              v-if="$store.state.session.profile.cartGoodsCount > 0"
            >
              {{ $store.state.session.profile.cartGoodsCount }}
            </span>
          </a>
          <cartMini @openCartModal="openCartModal" :display="modalCartStyle" />
        </div>
      </nav>
    </header>
    <div class="tabbar_wrap" v-show="showBottomBar">
      <div class="tabbar">
        <ul class="tabbar_inner">
          <li class="back">
            <a href="javascript:;" class="bt_back_tab" @click.prevent="onBack()">
              BACK
            </a>
          </li>
          <li class="search">
            <a href="javascript:;" class="bt_search_tab" :class="getClazz('검색 결과')" @click.prevent="openSearchModal()">
              SEARCH
            </a>
          </li>
          <li class="home">
            <a href="/" class="bt_home_tab" :class="getClazz('Today')">
              HOME
            </a>
          </li>
          <li class="wish">
            <a href="/my-page/wish-list" class="bt_wish_tab" :class="getClazz('위시리스트')">
              SAVED
            </a>
          </li>
          <li class="mypage">
            <a
              href="/my-page/main"
              class="bt_user_tab"
              :class="getClazz('MY PAGE')"
            >
              ACCOUNT
              <!--span
                class="mypage_badge"
                v-if="$store.state.session.profile.giftReceiveCount > 0"
              >
                {{ $store.state.session.profile.giftReceiveCount }}
              </span-->
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- s: 전체메뉴 ; display:none 상태입니다. -->
    <CategoryModal @openCategoryModal="openCategoryModal" :modal-category-style="modalCategoryStyle"></CategoryModal>
    <!-- e: 전체메뉴 ; display:none 상태입니다. -->
  </div>
</template>

<script>
import SearchModal from "@/components/commons/modal/SearchModal";
import CategoryModal from "@/components/commons/modal/CategoryModal.vue";
import CartMini from "@/components/pages/cart/mini/Mobile.vue";
import Desktop from "@/components/pages/cart/mini/Desktop";

// import { Autoplay, Pagination, Navigation } from "swiper";

export default {
  components: {
    'CategoryModal' : CategoryModal,
    CartMini,
  },
  extends: Desktop,
  data: () => ({
    searchModal: undefined,
    modalCartStyle: 'none',
    modalCategoryStyle: 'none',
    lastScrollY: 0,
    isMoveScroll: false,
    isMain: false,
    showBottomBar: true,
    swiperOptions: {
      direction: 'vertical',
      slidesPerView: 1,
      loop: true,
      speed : 1000,
      autoHeight: true,
      height:42,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
    },
    textBannerList: [],
    textBannerRollingList: [],
    textBannerIndex: 1,
    textBannerRolling: undefined,
  }),
  computed: {
    headerTitle() {
      let routeName = this.$store.state.layout.routeName;
      if (["메인", "카테고리", "까웨", "까웨(K-Way)", "상품상세", "TOP", "Today"].indexOf(routeName) > -1) {routeName = "home";}
      return routeName;
    },
    isMainCheck() {
      return this.isMain;
    },
    isShowHistoryBack() {

      if (["/goods", "/cart"].indexOf(this.$route.path) > -1) {
        return true;
      }
      return false
    },
    chkRoute(){
        let routeName = this.$store.state.layout.routeName;
        if (["메인", "카테고리", "까웨", "까웨(K-Way)", "상품상세", "TOP", "Today"].indexOf(routeName) > -1 || ["/category"].indexOf(this.$route.path) > -1) {
            return true;
        }else{
            return false;
        }

    }
  },
  watch: {
    '$route': 'fetchData'
  },

  created() {
    this.fetchData()
    //window.addEventListener('scroll', this.rAFWrapper(this.handleScroll));
  },

  beforeDestroy: function () {
    // 핸들러 제거하기(컴포넌트 또는 SPA의 경우 절대 잊지 말아 주세요!)
    try {
      window.removeEventListener('scroll', this.rAFWrapper(this.handleScroll))
    } catch (error) {
    }
  },


  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.rAFWrapper(this.handleScroll));
    });
    try {
      this.textBannerList = await this.$axios.$get("/api/main/text-banner");
      this.textBannerRollingList = this.textBannerList;
      // console.log('textBannerRollingList : ', this.textBannerRollingList);
      // if (this.textBannerList != undefined && this.textBannerList.length > 1) {
      //   this.textBannerRolling = setInterval(this.textBannerRollingEventHandler, 10000);
      //   }
    } catch (e) {
    }
  },

  methods: {
    async openSearchModal() {
      await this.$_rk.load();
      let popular = await this.$axios.$get("/diquest/popular");
      this.$modal.show(
        SearchModal,
        {
          popular,
          update: (ref) => {
            this.searchModal = ref;
          }
        },
        {
          width: "100%",
          height: "100%",
        }
      );
    },
    openCartModal() {
      this.$router.push('/cart');
      return;

      /*
      //미니카트 안씀
      if (this.modalCartStyle == 'none') {

        this.modalCartStyle = 'block';
      } else {
        this.modalCartStyle = 'none';
      }
      */
    },
    openCategoryModal() {
      if (this.modalCategoryStyle == 'none') {
        this.modalCategoryStyle = 'block';
      } else {
        this.modalCategoryStyle = 'none';
      }
    },
    rAFWrapper(callback) {
      let tick = false

      return function trigger() {
        if (tick) {
          return
        }

        tick = true
        return requestAnimationFrame(function task() {
          tick = false
          return callback()
        })
      }
    },
    handleScroll: function () {
      let cp = window.scrollY;
      if (this.isMainCheck) {
        if (cp > 0) {
          this.isMoveScroll = true;
        } else {
          this.isMoveScroll = false;
        }
      }
      const lp = this.lastScrollY
      if (lp >= 0 && cp > lp) {
        this.$store.commit('scroll-direction/set', 'DOWN')
      } else {
        this.$store.commit('scroll-direction/set', 'UP')
      }
      this.lastScrollY = cp
    },
    fetchData() {
      let routeName = this.$store.state.layout.routeName;
      if ("Today" == routeName) {
        this.isMain = true;
        this.isMoveScroll = false;
      } else {
        this.isMain = false;
        this.isMoveScroll = true;
      }

      if ("상품상세" == routeName) {
        this.showBottomBar = false
      }
      else {
        this.showBottomBar = true
      }
    },
    onBack() {
      this.$router.go(-1);
    },
    textBannerRollingEventHandler() {
      let nextIndex = this.textBannerIndex + 1;
      if (nextIndex >= this.textBannerList.length) nextIndex = 0;
      this.textBannerIndex = nextIndex;

      let t = 0;
      let animation = setInterval(() => {
        let step = 43 / 200;
        t = t - step;
        try {
          this.$refs["textBanner"].style.top = t + "px";
        } catch (e) {
        }

        if (t <= -43) {
          clearInterval(animation);
          try {
            this.$refs["textBanner"].style.top = 0;
          } catch (e) { }
          this.textBannerRollingList.shift();
          if (this.textBannerList[this.textBannerIndex]) {
            this.textBannerRollingList.push(this.textBannerList[this.textBannerIndex]);
          }
        }
      }, 1);
    },
    getClazz(page){
        let routeName = this.$store.state.layout.routeName;
        const clazz = [];
        if(page === routeName){
            clazz.push('tab_on');
        }
        return clazz;
    }
  },
}
</script>
