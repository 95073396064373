export default async ({app, $axios}) => {
  if (app.store.state.category.isEmpty) {
    let categories = [];
    try {
      categories = await $axios.$get("/api/category");
    } finally {
      app.store.commit("category/set", categories);
    }
  }

  if(app.store.state['size-sort'].isEmpty) {
    let sizeSort = [];
    try {
      sizeSort = await $axios.$get("/api/code", {params : {grpCd : "SIZE_CD"}});
    } finally {
      app.store.commit("size-sort/set", sizeSort);
    }
  }
}
