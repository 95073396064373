<template>
  <form id="wrap" @submit.prevent="doSearch()" novalidate class="pu_search">
    <!-- Header -->
    <header class="header_wrap">
      <nav class="header_pop">
        <button type="button" class="bt_closeall" @click.stop="$emit('close')">
          닫기
        </button>
      </nav>
    </header>
    <!--// Header -->
    <!-- Container -->
    <main class="contents">
      <section>
        <!-- 검색창 -->
        <div class="input_search_wrap wrapper">
          <div class="input_common search">
            <input placeholder="검색어를 입력해주세요." type="text" v-model="query" required="required" autofocus ref="searchField">
            <button type="button" class="clear" @click.stop="query = undefined">초기화</button>
            <button type="submit" class="bt_srch_tab" style="color: black; width: 60px;">Search</button>
          </div>
        </div>
        <!-- 자동완성 -->
        <div class="srch_list_wrap auto" style="display: none;">
          <ul class="srch_list_inner">
            <li>
              <a href="">롱코트</a>
            </li>
            <li>
              <a href="">숏패딩</a>
            </li>
            <li>
              <a href="">스커트</a>
            </li>
          </ul>
        </div>
        <!-- 최근 검색어 -->
        <div class="srch_list_wrap" style="display:none">
          <div class="title_box wrapper">
            <h4 class="title_2nd">추천 검색어</h4>
          </div>
          <ul class="srch_list_gray swipe_hd">
            <li><a href="" @click.prevent="doSearch('르브레 클로드 쟈켓')">르브레 클로드 쟈켓</a></li>
            <li><a href="" @click.prevent="doSearch('르브레 레옹 그래픽 셔츠')">르브레 레옹 그래픽 셔츠</a></li>
            <li><a href="" @click.prevent="doSearch('까웨 가을 기획전')">까웨 가을 기획전</a></li>
            <li><a href="" @click.prevent="doSearch('르브레 클로드 쟈켓')">르브레 클로드 쟈켓</a></li>
            <li><a href="" @click.prevent="doSearch('르브레 레옹 그래픽 셔츠')">르브레 레옹 그래픽 셔츠</a></li>
            <li><a href="" @click.prevent="doSearch('까웨 가을 기획전')">까웨 가을 기획전</a></li>
          </ul>
        </div>
        <!-- 최근 검색어 -->
        <div class="srch_list_wrap wrapper">
          <div class="title_box">
            <h4 class="title_2nd">최근 검색어</h4>
            <a href="" class="bt_text_s_9 pos_rtxt" v-if="$_rk.recentKeywords.length > 0" @click.prevent="clearKeywords()">
              전체삭제
            </a>
          </div>

          <ul class="srch_list_inner" v-if="$_rk.recentKeywords.length > 0">
            <li
              v-for="(recent, recentIndex) in $_rk.recentKeywords"
              :key="`recent-${recentIndex}`"
            >
              <a href="" @click.prevent="doSearch(recent.keyword)">{{ recent.keyword }}</a>
              <span class="srch_date">{{ formatDate(recent.timestamp, "MM.DD") }}</span>
              <button type="button" class="bt_i_del" @click.stop="removeKeyword(recent.keyword)">삭제</button>
            </li>
          </ul>

          <div class="nodata_g" v-else>
            최근 검색어가 없습니다.
          </div>
        </div>

        <!-- 인기 검색어 -->
        <div class="srch_list_wrap wrapper">
          <div class="title_box">
            <h4 class="title_2nd">인기 검색어</h4>
          </div>
          <ul class="srch_list_inner favor">
            <!-- <li>
              <a href="#;">
                <i class="num">1</i>
                <span class="keyword">르브레 클로드 자켓르브레 클로드 자켓르브레 클로드 자켓르브레 클로드 자켓르브레 클로드 자켓르브레 클로드 자켓르브레 클로드 자켓르브레 클로드 자켓</span>
                <span class="up"></span>
              </a>
            </li> -->
            <li
              v-for="(p, popularIndex) in popular"
              :key="`popular-${popularIndex}`"
            >
              <a href="" @click.prevent="doSearch(p.keyword)">
                <i class="num">{{ p.ranking }}</i>
                <span class="keyword">{{ p.keyword }}</span>
                <span :class="getRankingClass(p)"></span>
              </a>
            </li>
          </ul>
        </div>
      </section>
    </main>
  </form>
</template>

<script>
import DesktopSearchModal from "@/components/commons/modal/DesktopSearchModal";

export default {
  extends: DesktopSearchModal
};
</script>
