<template>
  <footer class="footer_wrap">
    <ul class="footer_menu">
      <li><a href="javascript:;" aria-label="개인정보 처리방침 페이지 이동" @click.prevent="openPrivacypolicyModal()" class="privacy">개인정보처리방침</a></li>
      <li><a href="javascript:;" aria-label="이용약관 페이지 이동" @click.prevent="openTermsModal()">이용약관</a></li>
      <li><a target="_blank" aria-label="회사소개" href="https://www.lottegfr.co.kr/#appblank=y">회사소개</a></li>
      <!--<li><nuxt-link to="/footer/adfilm">AD FILM</nuxt-link></li>-->
      <!--<li><nuxt-link to="/footer/catalogue">CATALOGUE</nuxt-link></li>-->
      <div class="foot_family"><a href = "javascript:;" aria-label="패밀리사이트 Select Box 오픈" onclick = "document.getElementById('light').style.display='block';document.getElementById('fade').style.display='block'">FAMILY SITE+</a></div>
      <!-- <li><a href="#" @click.prevent="memberBenefit()">L.POINT 통합 회원 혜택</a></li> -->
    </ul>
    <div id="light" class="pop_family">
      <a target="_blank" aria-label="패밀리사이트 나이스클랍 이동" href="https://www.niceclaup.co.kr/">NICE CLAUP</a><br />
      <a target="_blank" aria-label="패밀리사이트 CANADAGOOSE 이동" href="https://www.canadagoose.co.kr/">CANADA GOOSE</a><br />
      <a href="javascript:;" aria-label="패밀리사이트 Select Box Close" onclick="document.getElementById('light').style.display='none';document.getElementById('fade').style.display='none'">FAMILY SITE-</a>
    </div>
    <div class="footer_graytag">
      <nuxt-link to="/footer/story" aria-label="K-WAY 스토리 페이지 이동" class="bt_white_gs">K-WAY STORY</nuxt-link>
      <nuxt-link to="/footer/store" aria-label="K-WAY 매장정보 페이지 이동" class="bt_white_gs">STORE</nuxt-link>
      <a :href="appDownloadURL" aria-label="K-WAY 앱 다운로드 이동" class="bt_white_gs">APP DOWNLOAD</a>
      <!-- <nuxt-link to="/footer/partner" class="bt_white_gs">제휴문의</nuxt-link> -->
    </div>
    <div class="foot_r_wrap">
      <dl>
        <dt>
          고객센터 {{ formatPhone(footerData.csTelNo) }}
          <div class="go_cs"><nuxt-link to="/customer/main">고객센터 바로가기 ></nuxt-link></div>
        </dt>
        <dd><span>상담시간 </span> | 평일(월~금) 09:30 - 17:00 <br />
            <span> </span> 점심시간 : 12:30 - 13:30(토/일/공휴일 휴무)</dd>
        <dd><span>입금계좌 </span> | 신한 140-013-410230 롯데지에프알(주)</dd>
        <dd><span>교환ㆍ반품주소 </span> | 경기도 화성시 동탄산단 1길 4, <br />
        <span> </span> 롯데지에프알 물류센터(우:18487)</dd>
        <dd><span>E-mail 주소 </span> | gfrhelp@lotte.net</dd>
      </dl>
    </div>
    <ul class="sns_colli">
      <li><a target="_blank" aria-label="K-WAY 공식 페이스북 이동" href="http://www.facebook.com/kway.official" class="facebook">Facebook</a></li>
      <li><a target="_blank" aria-label="K-WAY 공식 인스타그램 이동" href="https://www.instagram.com/kway_official" class="instagram">Instagram</a></li>
      <li><a target="_blank" aria-label="K-WAY 공식 유튜브 이동" href="http://www.youtube.com/KWay_official" class="youtube">Youtube</a></li>
      <li><a target="_blank" aria-label="K-WAY 공식 카카오채널 이동" href="http://pf.kakao.com/_yKlxds" class="kakaoChannel">Kakao Channel</a></li>
    </ul>
    <ul class="footer_info">
      <li class="mgb10">{{ footerData.compNm }}</li>
      <li>대표 : {{ footerData.ceoNm }}</li>
      <li>{{ footerData.roadAddr }} {{ footerData.roadDtlAddr }}</li>
      <li>개인정보보호 책임자 : {{ footerData.pipOfcNm }}</li>
      <li>사업자등록번호 : {{ formatBizRegNo(footerData.bizNo) }}</li>
      <li>통신판매업신고번호 : {{ footerData.mosApprNo }}</li>
      <li class="copyright">
        COPYRIGHT(C) 2022 BY LOTTE GFR. ALL RIGHTS RESERVED.
      </li>
    </ul>
  </footer>
</template>

<script>
import PrivacypolicyModal from "@/components/pages/footer/privacypolicy/Mobile";
import TermsModal from "@/components/pages/footer/terms/Mobile";
import MemberBenefit from "@/components/commons/modal/MemberBenefit";

export default {
  name: "MobileFooter",

  computed: {
    footerData() {
      return this.$store.state.session.site;
    },
    appDownloadURL() {
      if (!this.$store.state.layout.isApp) {
        if (this.$device.isAndroid) {
          return "https://play.google.com/store/apps/details?id=m.lottegfr.kway";
        } else if (this.$device.isIos) {
          //return "itms-apps://itunes.apple.com/app/1556789718";
          return "https://apps.apple.com/us/app/kway-%EA%B9%8C%EC%9B%A8/id1614040143";
        }
      }
      return undefined;
    }
  },

  methods: {
    openPrivacypolicyModal() {
      this.$modal.show(
        PrivacypolicyModal,
        {},
        {
          width: "100%",
          height: "100%",
        }
      );
    },
    openTermsModal() {
      this.$modal.show(
        TermsModal,
        {},
        {
          width: "100%",
          height: "100%",
        }
      );
    },
    memberBenefit() {
      this.$modal.show(MemberBenefit, {}, {
        width: this.$device.isMobile ? "100%" : "400px",
        height: "auto",
      });
    },
  },
};
</script>
<style scoped>
.pop_family {
  height: auto;
}
</style>