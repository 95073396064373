<template>
  <div id="wrap">
    <!-- header -->
    <desktop-header />
    <!-- //header -->

    <!-- Container -->
    <main class="contents">
      <nuxt :key="$pageUtil.getKey()"/>
    </main>
    <!-- Container -->

    <top-button />

    <!-- footer -->
    <desktop-footer/>
    <!-- //footer -->
    <!-- cre.ma / 공통 스크립트 (PC) / 스크립트를 수정할 경우 연락주세요 (support@cre.ma) -->
    <script>(function(i,s,o,g,r,a,m){if(s.getElementById(g)){return};a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.id=g;a.async=1;a.src=r;m.parentNode.insertBefore(a,m)})(window,document,'script','crema-jssdk','//widgets.cre.ma/niceclaup.co.kr/init.js');</script>
  </div>
</template>

<script>
import DesktopFooter from '@/components/commons/desktop/DesktopFooter.vue';
import DesktopHeader from '@/components/commons/desktop/DesktopHeader.vue';
import TopButton from "@/components/commons/TopButton.vue";

export default {
  components: {
    DesktopHeader,
    DesktopFooter,
    TopButton,
  },

  head: function() {
    // console.log(this.userProfile)
    return {
      link: [
        {
          rel: 'preload',
          href: '/web/css/common.css',
          as: 'style'
        },
        {
          rel: 'stylesheet',
          href: '/web/css/common.css',
        },
      ],
      script: [
        {
          type: 'text/javascript',
          src: process.env.KCP_JS_URL,
        },
        {
          type: 'text/javascript',
          src: '//t1.daumcdn.net/adfit/static/kp.js'
        }
      ],
    }
  },

  beforeMount() {
    let session = this.$store.state.session;
    let userId =  null;
    let userName = null;

    if(session.isMember) {
      userName = `${session.profile.name}`;
      userId = `${session.profile.loginId}`
    }

    try {
      window.cremaAsyncInit = function () {
        crema.init(userId, userName)  
        // console.log(`Crema init on desktop`)
        // console.log(`userId: ${userId}, userName: ${userName}`)
      }
      window.cremaAsyncInit()
    } catch (error) {
      // console.log(`crema init error\n> ${error}`)
    }
  },


  mounted() {
    try{
      if (typeof kakaoPixel !== "undefined") {
        kakaoPixel('8540025084970616763').pageView();
      }
    } catch (e){

    }
  },

};
</script>
