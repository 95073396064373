export function cfGetValue(value) {
  if (value === null || value === undefined) return '';
  return String(value).trim();
}
  
export function cfFormatPhone(value) {
  value = cfGetValue(value);
  value = value.replace(/\D/g, '');
  if (value.match(/^(02|\d{2,3})(\d{3,4})(\d{4})$/g)) {
    return value.replace(/^(02|\d{2,3})(\d{3,4})(\d{4})$/g, '$1-$2-$3');
  } else if (value.match(/^(\d{4})(\d{4})$/g)) {
    return value.replace(/^(\d{4})(\d{4})$/g, '$1-$2');
  } else return '';
}