const noReloadingPages = [
  'my-page-recently-goods',
  'my-page-mileage',
];

class PageUtil {
  constructor($route) {
    this.$route = $route;
  }

  getKey() {
    /**
     * query parameter 변경시 화면 reload 되지 않고 유지 되어야 하는경우에는 name 을 key 설정하고 그 외에 경우에는 fullPath 를 키로 설정한다.
     */
    return noReloadingPages.indexOf(this.$route.name) > -1 ? this.$route.name : this.$route.fullPath;
  }

  /**
   * route.query를 arg로 받아서 URL에 담겨있는 Query String만 추출해서 return
   * @param {*} queryStringObj
   * @returns 
   */
  getUrlQueryString(queryStringObj){
    let appendParam = '';
    if( Object.keys(queryStringObj || {}).length > 0 ) {
      let urlSearchParams = new URLSearchParams(queryStringObj);
      appendParam = `?${urlSearchParams.toString()}`;
    }
    return appendParam;
  }
  
}


export default (context, inject) => {
  const util = new PageUtil(context.route);
  context.app.$pageUtil = util;
  inject("pageUtil", util);
}
