<template>
  <section class="sub_para02">
    <div class="mar_set wrapper">
      <div class="title_box">
        <h3 class="title_1st">이용약관</h3>
      </div>
      <div class="policy_header">
        <div class="txt_info fl">
          <p>공고일자 :&nbsp;<span>{{ selectedPolicy.sysRegDtm | dateFormat }}</span></p>
          <p>시행일자 :&nbsp;<span>{{ selectedPolicy.sysRegDtm | dateFormat }}</span></p>
        </div>
        <div class="sel_common rborder w300 fr">
          <select required v-model="selectedPolicy">
            <option v-for="policy in policies" :value="policy">
              v{{ policy.versionInfo }} (개정일자 : {{ policy.sysRegDtm | dateFormat }})
            </option>
          </select>
        </div>
      </div>
      <!-- 콘텐츠박스 -->
      <div class="terms_con" v-html-custom="selectedPolicy.content">
      </div>
      <!-- //콘텐츠박스 -->
    </div>
  </section>
</template>

<script>
export default {

  data() {
    return {
      policies: [],
      selectedPolicy: {},
    }
  },
  async fetch() {
    this.policies = await this.$axios.$get('/api/policy/terms');
    this.selectedPolicy = this.policies[0];
  },
};
</script>
