import moment from 'moment'

export default {
  methods: {

    /**
     * 롯데멤버스 자동 로그인
     */
    async doLMembersSsoAutoLogin(rnwTkn, callbackFunc) {

      // SSO 초기화
      let sso = null;
      let self = this;

      sso = await this.$ssoLib.initSsoLogin()
      let autoLgnRgDtti = moment().format("YYYYMMDDhhmmss");
      sso.callSsoLogin({
        akUrl: '/exBiz/login/autoLogin_01_001',   // 요청URL
        akDta: {
          rnwTkn: rnwTkn,
          autoLgnRgDtti: autoLgnRgDtti
        },
        aftPcMd: '0',                            // 후처리모드
        rturUrl: window.location.href,           // 리턴URL
        rturUrlCaloMethd: 'GET',                 // 리턴URL호출메소드
        callback: async function(rspDta) {
          if('00' == rspDta.rspC) {
            let objData = {};
            objData["lMembersSsoTkn"]  = rspDta.ssoTkn;
            objData["lMembersAcesTkn"] = rspDta.acesTkn;
            objData["lMembersRnwTkn"]  = rspDta.rnwTkn;
            
            window.sessionStorage.setItem('lMembersTknObj', JSON.stringify(objData))
            self.$store.commit("session/setLMembersTkn", objData);

            // TODO CONSOLE.LOG 제거
            // AutoLogin일 경우 토큰 쿠키에 저장
            const autoLoginStatus = self.$cookies.get('AUTO_LOGIN')
            console.log('>>Autologin status1', autoLoginStatus)

            if (autoLoginStatus == 'Y') {
              self.$cookies.set(process.env.SSO_RNW_TKN_KEY, rspDta.rnwTkn, {
                path: '/',
                maxAge: 360 * 24 * 60 * 60,
              })
              console.log('Save autologin token1', self.$cookies.get(process.env.SSO_RNW_TKN_KEY))
            }

            if(callbackFunc && typeof callbackFunc == 'function') {
              callbackFunc(rspDta);
            }
          }
        }
      });
    },

    /**
     * 롯데멤버스 로그인
     */
    async doLMembersSsoLogin(ssoTkn) {

      // SSO 초기화
      let ssoClientInitInfo = await this.$lMembersService.ssoClientInit();

      let urEvnmtDc = "0";
      if(this.$device.isMobile) {
        urEvnmtDc = "1";
      }

      let sso = null;
      let self = this;      

      sso = new SsoClientLibrary({
        ccoSiteNo: ssoClientInitInfo.ccoSiteNo,  // 제휴사사이트번호
        clntAkInf: ssoClientInitInfo.clntAkInf,  // 클라이언트요청정보
        vrblNm: "sso",						 		// 라이브러리 변수명
        urEvnmtDc : urEvnmtDc,        // 사용자환경구분코드 0: PC Web 1: Mobile Web 2: Mobile App
        srnOpt : {opMd : '0'}         // 오픈모드 0: Redirect 1: iframe 2: Popup
      });
      
      var options = {
        akUrl: '/exBiz/login/ssoLogin_01_001',   // 요청URL
        akDta: {
          ssoTkn: ssoTkn
        },
        aftPcMd: '0',                            // 후처리모드
        rturUrl: window.location.href,           // 리턴URL
        rturUrlCaloMethd: 'GET',                 // 리턴URL호출메소드
        callback: async function(rspDta) {
          if('00' == rspDta.rspC) {
            let objData = {};
            objData["lMembersSsoTkn"]  = rspDta.ssoTkn;
            objData["lMembersAcesTkn"] = rspDta.acesTkn;
            objData["lMembersRnwTkn"]  = rspDta.rnwTkn;

            self.$store.commit("session/setLMembersTkn", objData);

            // TODO CONSOLE.LOG 제거
            // AutoLogin일 경우 토큰 쿠키에 저장
            const autoLoginStatus = self.$cookies.get('AUTO_LOGIN')
            console.log('>>Autologin status2', autoLoginStatus)

            if (autoLoginStatus == 'Y') {
              self.$cookies.set(process.env.SSO_RNW_TKN_KEY, rspDta.rnwTkn, {
                path: '/',
                maxAge: 360 * 24 * 60 * 60,
              })
              console.log('Save autologin token2', self.$cookies.get(process.env.SSO_RNW_TKN_KEY))
            }

            let form = [
              `lMembersAcesTkn=${self.$store.state.session.lMembersAcesTkn}`
            ];

            await self.$axios
            .$post("/api/login", form.join("&"), {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-App-No": self.$store.state.layout.appNo,
                "X-App-YN": self.$store.state.layout.isApp ? "Y" : "N",
                "Cache-Control": "no-cache, no-store, must-revalidate"
              }
            })
            .then(async (response) => {
              let data = (response || {});
              if(data.code == 'SUCCESS_LOGIN'){
                await self.afterLoginHandler(false, true);
                let profile = await self.$axios.$get("/api/user/me");                  
                self.$store.commit("session/set", profile);
                // setCookie("AUTO_LOGIN", "N", 0);
              }
            })
            .catch(async (err) => {

            });
          }
        }
      };
  
      sso.callSsoLogin(options);
    },

    async afterLoginHandler(isAutoLogin, isSsoLogin = false) {

      try {
        let mbrNoEnc = await this.$axios.$get("/api/member/enc");
        if(mbrNoEnc && mbrNoEnc != "") {
          let param = {
            custId : mbrNoEnc
            , userInfo : {}
          }

          if(this.$device.isMobile){
            //모바일일경우
            if (window.skyDroid) {
              //Android
              var paramStr = JSON.stringify(param);
              window.skyDroid.pmsDeviceCert(paramStr);
            } else {
              //iOS
              window.webkit.messageHandlers.pmsDeviceCert.postMessage(param);
            }
          }

        }
      } catch (e) {}

      try {
        let muuid = await this.getCookie("muuid");
        let utmSource = window.sessionStorage.getItem('UTM_SOURCE');
        let utmCampaign = window.sessionStorage.getItem('UTM_CAMPAIGN');
        let utmMedium = window.sessionStorage.getItem('UTM_MEDIUM');

        if(!utmSource) utmSource = null;
        if(!utmMedium) utmMedium = null;
        if(!utmCampaign) utmCampaign = null;
        
        await this.$axios.$post("/api/member/referrer/update", {
          ssnId : muuid,
          utmSource,
          utmCampaign,
          utmMedium
        });
      } catch (e) {}

      // 장바구니 Merge 및 Get
      await this.$cart.get(true);
      // 장바구니 ID를 날리자 -> 회원으로 전환되면서...
      this.$cart.clear();
      this.$store.commit("session/clear");
      await this.$_wish.get();

      let result = await this.$axios.$post("/api/member/registcoupon");
      if(result > 0){
        await this.$modal.success("쿠폰이 발급되었습니다. 마이페이지 > 쿠폰 내역을 확인해주세요.")
      }

      try {
        await this.$crema.userUpdate("Login Crema update");
      } catch (e){}

      // let profile = await this.$axios.$get("/api/user/me");

      // let userId =  null;
      // let userName = null;

      // if(profile.name !== 'GUEST') {
      //   userName = `${profile.name}`;
      //   userId = `${profile.loginId}`
      // }

      // try {
      //   window.cremaAsyncInit = function () {
      //     crema.init(userId, userName)  
      //   }
      // } catch (error) {
      // }

      /**
       * GFR 회원일 경우 통합회원 유도 팝업 
       * 일단 주석 개발계에서 충분한 테스트가 필요함
       * 
      **/
      try {
        const showConvPop = this.$cookies.get('HIDE_CONV_POP')
        
        if (!showConvPop) {
          let { lotteMembers } = await this.$axios.$get("/api/user/me");                  
          if (!lotteMembers) {
            try {
              await this.$modal.gftMemAfterLogin()
            } catch (error) {
              return
            }
          }
        }
      } catch (error) {
        console.error(error)
      }

      /** SSO 회원가입 후 리다이렉트 */
      if(isSsoLogin) {
        try {
          if (['/join/sso'].indexOf(this.$route.path) >= 0) {
            // referrer === ('order_join' || 'gift_join')
            let ref = this.$route.query.referrer;
            if (ref) {
              if (ref === 'order_join') {
                // console.log('[/join/sso/afterLogin/redirect] success referrer')
                return this.$router.push("/order");
              }
              if (ref === 'gift_join') {
                // console.log('[/join/sso/afterLogin/redirect] success referrer')
                return this.$router.push("/gift");
              }
            }
            
            // !referrer
            let path = this.$cookies.get("LOGIN_REDIRECT") || '/';
            let query = this.$cookies.get("LOGIN_REDIRECT_QUERY") || '';
            
            // console.log('[/join/sso/afterLogin/redirect] success cookie')
            return this.$router.push({ path, query })
          }
        } catch (error) {
          // console.log('[/join/sso/afterLogin/redirect]', error)
          return this.$router.push('/')
        }
      }

      if(isAutoLogin){
        if(['/login'].indexOf(this.$route.path) >= 0) {
          let path = this.$cookies.get("LOGIN_REDIRECT");
          let query = this.$cookies.get("LOGIN_REDIRECT_QUERY");          
          if(path == '/member/reset-password'){
            return this.$router.push({path: "/"});
          }
          return this.$router.push({ path: path || "/" ,query:query || ""});
          //return this.$router.push("/");
        }
        return;
      }


      if(['/join/form','/join/simple-form'].indexOf(this.$route.path) >= 0) {
        /** 
         * 2023-01-16 jkjang
         * [회원가입이면서 주문하기가 아닌경우]
         * -가입완료 이후 로그인 자동으로 시키며 가입완료 페이지로 가야함
        */

        let params = {
          doReceiveEmail: this.form.doReceiveEmail,
          doReceiveSms: this.form.doReceiveSms,
          //둘다 동의일경우 쿠폰수신 ok
          coupon: (this.form.doReceiveEmail && this.form.doReceiveSms),
          paramCheck: true,
          referrer: this.$route.query.referrer,
          joinType: this.form.type || 'SITE',
          isLogin: false
        }

        return this.$router.push({ name: "join-welcome", params: params });
      } else if (this.$route.query.referrer === "order") {
        // return this.$router.push("/order");
        return window.location = '/order'
      } else if(this.$route.query.referrer === "gift"){
        // return this.$router.push("/gift");
        return window.location = '/gift'
      } else {
        let pswdChk = await this.$axios.$get("/api/member/statuspassword");
        if(pswdChk){
          return this.$router.push("/member/expire-password");
        }else{
          let path = this.$cookies.get("LOGIN_REDIRECT");
          let query = this.$cookies.get("LOGIN_REDIRECT_QUERY");
          if(path == '/member/reset-password'){
              return this.$router.push({path: "/"});
            }
          return this.$router.push({ path: path || "/" ,query:query || ""});
        }
      }

    },

    /**
     * OAuth 로그인
     * @param id
     * @param token
     * @returns {Promise<Route>}
     */
    async doOAuthLoginChain(type, token) {
      let form = [`type=${type}`, `token=${token}`].join("&");
      try {
        await this.$axios.$post("/api/oauth/login", form, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-App-No": this.$store.state.layout.appNo,
            "X-App-YN": this.$store.state.layout.isApp ? "Y" : "N"
          },
        });
        return this.afterLoginHandler();
      } catch (e) {
        if (((e || {}).response || {}).status === 404) {
          let data = ((e || {}).response || {}).data || {};
          this.$store.commit("simple-join/set", {
            details: data.details,
            refreshToken: token,
          });
          return this.$router.push({ path: "/join/simple-form", query: {...this.$route.query
            , referrer: ['order','gift'].indexOf(this.$route.query.referrer) >= 0 ? 
                this.$route.query.referrer+'_join' : 
                this.$route.query.referrer } });
        } else {
          return this.handleLoginError(e);
        }
      }
    },

    async handleLoginError(e) {
      let state = ((e || {}).response || {}).status;
      let data = ((e || {}).response || {}).data;
      let code = (data || {}).code || '';
      let message = data.message;

      if (state === 504) message = "서버에 접속할 수 없습니다.";
      else if (code === 'ERR_HIBERNATE_USER') {
        let cache = JSON.stringify(data);
        cache = encodeURI(cache);
        this.$cookies.set(process.env.DORMANT_INFO_KEY, btoa(cache), {
          path: '/',
          maxAge: 60 * 5
        });
        return this.$router.push({path: '/member/active'});
      }
      else if (state === 403) {
        return;
      }
      return this.$modal.error(message);
    },

    async getCookie (name) {
      var value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
      return value? value[2] : null;
    },

    async setCookie (name, value, exp) {
      var date = new Date();
      date.setTime(date.getTime() + (exp*24*60*60*1000) );
      document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';
    },

    setAutoLoginCookie() {
      // autoLogin 가능한 세션인지 cookie 세팅
      if((this.form || {}).autoLogin){
        //this.$cookies.set("AUTO_LOGIN", "Y");
        this.setCookie("AUTO_LOGIN", "Y", 360);
      } else {
        //this.$cookies.remove("AUTO_LOGIN");
        this.setCookie("AUTO_LOGIN", "N", 0);
      }
    },
  },
};
