<template>
  <div class="popup_layer" :class="{ desktop: $device.isDesktop, mobile: !$device.isDesktop }">
    <div class="popup_header flex low between">
      <h5 class="title_pop"><span class="txt_blue strong">L.POINT 통합 회원</span> 전환</h5>
      <button type="button" class="bt_closepop" @click.stop="onClose">닫기</button>
    </div>
    <div class="popup_contents">
      <div class="terms_con flex col gap15">
        <p class="f13">
          L.POINT 통합 회원으로 전환하시면 구매시 포인트 적립 및 사용이 가능이 가능합니다.
          <br>
          L.POINT 통합 회원으로 전환 하시겠습니까?
        </p>
        <p class="f13">
          회원을 전환하지 않을 시 3개월간 해당 팝업은 노출 되지
          않습니다. 회원전환을 위해서는 마이페이지에서 L.POINT
          회원 전환 버튼을 선택하시기 바랍니다.
        </p>
      </div>
    </div>
    <div class="popup_footer">
      <div class="bt_4th">
        <button
          type="button"
          class="bt_popup_l rwhite_blueborder"
          @click.stop="onOkClicked()"
        >
          L.POINT 통합 회원으로 전환
        </button>
        <button
          type="button"
          class="bt_popup_l rgray_grayborder"
          @click.stop="onCancelClicked()"
        >
          3개월간 노출 안함
        </button>
      </div>
    </div>
  </div>
</template>


<script>
import Common from '@/assets/mixins/common'

export default {
  mixins: [Common],

  props: {
    title: {
      type: String,
      default: "",
    },
    contents: {
      type: String,
      default: "",
    },
  },

  methods: {
    onOkClicked() {
      this.onClickLMembersJoinBtn(true)
      this.$attrs.reject();
      this.$emit("close");
    },
    onCancelClicked() {
      this.$cookies.set('HIDE_CONV_POP', true, {
        path: '/',
        maxAge: 90 * 24 * 60 * 60,
      })
      this.$attrs.resolve();
      this.$emit("close");
    },
    onClose() {
      this.$attrs.resolve();
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.popup_layer {
  .popup_header{
    padding: 20px 20px 0;
    position: unset;
    height: auto;
  }
  .popup_contents {
    padding: 0 20px 20px;
    overflow:auto;
  }
  .popup_footer {
    padding: 0 20px 20px;
  }
  .title_pop{
    font-size: 16px;
    font-weight: 600;
    color:#000;
    text-align: center;
    margin-bottom: 20px;
    height: unset;
    padding-top: 0;
  }
  &.desktop {
    width: inherit;
    position: fixed;
    left: 50%;
    top: 50%;
    width: 400px;
    background-color: #fff;
    // border-radius: unset;
    z-index: 400;
    transform: translate(-50%,-50%);
    .bt_closepop {
      width: 24px;
      height: 24px;
      position: unset;
      font-size: 0;
      background: transparent url("/images/bt_close2.png") no-repeat 50% 50%;
      background-size: 12px auto;
    }
    .popup_contents {
      padding-bottom: 20px;

      .terms_con {
        border-top: 1px solid #e1e1e1;
        padding: 20px 20px 0 0;
        
        p {
          color: #727272;
          word-break: keep-all;
        }
      }
    }
    .bt_popup_l {
      width: unset;
      flex-grow: 1;
      flex-shrink: 1;
      -webkit-box-flex: 1;
      flex-basis: content;
    }
    .bt_popup_l ~ .bt_popup_l {
      margin-left: 5px;
    }
  }
  &.mobile {
    width: inherit;
    position: fixed;
    left: 50%;
    top: 50%;
    width: 98%;
    // height: 300px;
    background-color: #fff;
    // border-radius: unset;
    z-index: 400;
    transform: translate(-50%,-50%);
    overflow-y: auto;
    // padding: 20px;
    
    .bt_closepop {
      width: 24px;
      height: 24px;
      position: unset;
      font-size: 0;
      background: transparent url("/images/bt_close2.png") no-repeat 50% 50%;
      background-size: 12px auto;
    }
    .popup_contents {
      padding-bottom: 20px;
      

      .terms_con {
        border-top: 1px solid #e1e1e1;
        padding: 20px 20px 0 0;
        
        p {
          color: #727272;
          word-break: keep-all;
        }
      }
    }
    .bt_popup_l {
      width: unset;
      flex-grow: 1;
      flex-shrink: 1;
      -webkit-box-flex: 1;
      font-size: 13px;
      min-width: 180px;
      flex-basis: content;
    }
    .bt_popup_l ~ .bt_popup_l {
      margin-left: 0;
    }
    .popup_footer {
      left: 0;
      right: 0;
      bottom: 0;
      position: relative;
    }
    .bt_4th {
      display: flex;
      margin-top: 10px;
      flex-wrap: wrap;
      gap: 5px;
    }
  }
  
}
</style>