<template>
  <form id="wrap" @submit.prevent="doSearch()" class="bg_white_search" novalidate>
    <div class="wrapper">
      <!-- 레이어 닫기 -->
      <div class="sch_dtclose">
        <button type="button" @click.stop="$emit('close')">닫기</button>
      </div>
      <!-- //레이어 닫기 -->

      <!-- Container -->
      <main class="contents">
        <section>
          <div class="search_popup">
            <h4>무엇을 찾으시나요?</h4>
            <div class="input_box search">
              <!-- 검색어 입력 -->
              <input
                placeholder="검색어를 입력해주세요"
                type="text"
                v-model="query"
                required="required"
                autofocus
                ref="searchField"
              />
              <button type="submit" class="bt_search" style="color:black;">Search</button>
              <!-- //검색어 입력 -->

              <!-- s: 검색시 연관검색어 노출 팝업 ; display:none 상태입니다. -->
              <div class="search_same_wrap">
                <div class="search_same">
                  <ul>
                    <li><a href="#;">르브레 클로드 자켓</a></li>
                    <li><a href="#;">오안 남성 코튼 트윌 카고 팬츠</a></li>
                    <li><a href="#;">릴리 리버시블 폴라 플리스 자켓</a></li>
                    <li><a href="#;">르브레 클로드 그래픽 자켓</a></li>
                  </ul>
                </div>
              </div>
              <!-- e: 검색시 연관검색어 노출 팝업 -->
            </div>
            <div class="searchpop_wrap">
              <!-- 추천 검색어 노출 -->
              <div class="comond_searchli" v-if="false">
                <h5 class="sm_tit">추천 검색어</h5>
                <ul>
                  <li><a href="#;">르브레 클로드 쟈켓</a></li>
                  <li><a href="#;">르브레 레옹 그래픽 셔츠</a></li>
                  <li><a href="#;">까웨 가을 기획전</a></li>
                  <li><a href="#;">르브레 클로드 쟈켓</a></li>
                  <li><a href="#;">르브레 레옹 그래픽 셔츠</a></li>
                </ul>
              </div>
              <!-- //추천 검색어 노출 -->
              <div class="fl">
                <!-- 최근검색어 -->
                <div class="search_recently">
                  <h5 class="sm_tit">
                    최근 검색어
                    <span v-if="$_rk.recentKeywords.length > 0">
                      <button type="button" class="bt_white_m" @click.stop="clearKeywords()">전체 삭제</button>
                    </span>
                  </h5>

                  <template v-if="$_rk.recentKeywords.length > 0">
                    <dl
                      v-for="(recent, recentIndex) in $_rk.recentKeywords"
                      :key="`recent_${recentIndex}`"
                    >
                      <dt><a href="" @click.prevent="doSearch(recent.keyword)">{{ recent.keyword }}</a></dt>
                      <dd>
                        {{ formatDate(recent.timestamp, "MM.DD") }}
                        <button type="button" @click.stop="removeKeyword(recent.keyword)">
                          <img src="/web/images/bt_close_xs.png"/>
                        </button>
                      </dd>
                    </dl>
                  </template>

                  <div class="recently_no" v-else>최근 검색어가 없습니다.</div>
                </div>
                <!-- //최근검색어 -->
              </div>
              <div class="fr">
                <!-- 인기검색어 -->
                <div class="search_recently best">
                  <h5 class="sm_tit">인기 검색어</h5>
                  <dl
                    v-for="(p, popularIndex) in popular"
                    :key="`popular-${popularIndex}`"
                  >
                    <dt class="ranking_num">{{ p.ranking }}</dt>
                    <dt><a href="" @click.prevent="doSearch(p.keyword)">{{ p.keyword }}</a></dt>
                    <dd>
                      <span :class="getRankingClass(p)">
                        {{ getRankingText(p) }}
                      </span>
                    </dd>
                  </dl>
                </div>
                <!-- //인기검색어 -->
              </div>
            </div>
          </div>
        </section>
      </main>
      <!-- //Container -->
      </div>
  </form>
</template>

<script>
import {isEmptyString} from "@/assets/libs/validate";

export default {
  props: {
    popular: Array
  },

  data: () => ({
    query: undefined
  }),

  mounted() {
    // console.log('mounted search field');
    // this.$refs.searchField.focus();
  },

  methods: {
    async removeKeyword(keyword) {
      await this.$_rk.remove(keyword);
      this.$forceUpdate();
    },

    async clearKeywords() {
      await this.$_rk.clear();
      this.$forceUpdate();
    },

    async doSearch(keyword) {
      if (!isEmptyString(keyword)) {
        this.query = keyword;
      }

      if (isEmptyString(this.query)) {
        this.$modal.error("검색어를 입력해주세요.");
        this.$refs.query.focus();
      }
      await this.$_rk.add(this.query);
      this.$emit("close");

      return this.$router.push({ path: "/search", query: { query: this.query } });
    },

    getRankingClass(value) {
      let diff = value.ranking - value.prevRank;
      if (diff > 0) {
        return "down";
      } else if (diff < 0) {
        return "up";
      } else {
        return "nochange";
      }
    },

    getRankingText(value) {
      let diff = value.ranking - value.prevRank;
      if (diff > 0) {
        return "↓";
      } else if (diff < 0) {
        return "↑";
      } else {
        return "-";
      }
    }
  }
};
</script>
