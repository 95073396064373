import { render, staticRenderFns } from "./DaumPostcode.vue?vue&type=template&id=b9d8fabc&scoped=true&"
import script from "./DaumPostcode.vue?vue&type=script&lang=js&"
export * from "./DaumPostcode.vue?vue&type=script&lang=js&"
import style0 from "./DaumPostcode.vue?vue&type=style&index=0&id=b9d8fabc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9d8fabc",
  null
  
)

export default component.exports