<template>
  <div class="popup_layer opt_changepop">
    <!-- 옵션변경 -->
    <div class="popup_header">
      <h5 class="title_pop">옵션 변경</h5>
      <button class="bt_closepop" @click.stop="$emit('close')">닫기</button>
    </div>
    <div class="popup_contents">
      <div class="opt_chitem_info row_probox">
        <div class="thumb">
          <img
            :src="getImageUrl((goods || {}).imgPath)"
            onerror="this.src='/images/no-img.jpg'"
          />
        </div>
        <figcaption>
          <div class="pro_sminfo">
            <span class="sort_tit">{{ genderStr }}</span>
            <p class="pro_name">{{ (goods || {}).goodsNm }}</p>
          </div>
        </figcaption>
      </div>
      <!-- s : 상품옵션 -->
      <div class="item_dt_option">
        <dl>
          <dt>COLOR</dt>
          <dd class="item_dt_option_color">
            <ul>
              <li
                v-for="(color, colorIndex) in itemOptions['6']"
                :key="`color-${colorIndex}`"
              >
                <label>
                  <input
                    type="radio"
                    name="select_color"
                    v-model="selectItemOption['6']"
                    :value="color"
                    @change="onAttrSelected('6')"
                    :disabled="color.stockQty == 0"
                  />
                  <div :style="`background-color:${color.rgbVal}`"
                    :class="{ soldout : color.stockQty == 0 }"
                    v-if="['#ZZZZZZ'].indexOf(color.rgbVal) < 0 && ['Multi','MultiColor'].indexOf(color.attrVal) < 0">
                  </div>
                  <div 
                    :class="{ soldout : color.stockQty == 0 , multi:1}" 
                    v-else>
                  </div>
                </label>
              </li>
            </ul>
          </dd>
        </dl>
        <dl>
          <dt>SIZE</dt>
          <dd class="item_dt_option_bt">
            <ul class="filter_input">
              <li
                v-for="(size, sizeIndex) in itemOptions['7']"
                :key="`size-${sizeIndex}`"
              >
                <label>
                  <input
                    type="radio"
                    name="select_size"
                    v-model="selectItemOption['7']"
                    :value="size"
                    @change="onAttrSelected('7')"
                    :disabled="size.stockQty == 0"
                  />
                  <div
                  :class="{ soldout : size.stockQty == 0 }"
                  >{{ size.attrVal }}</div>
                </label>
              </li>
            </ul>
          </dd>
        </dl>
        <dl>
          <dt>수량</dt>
          <dd>
            <div class="item_option_count_wrap">
              <button type="button" class="bt_option minus" @click.stop="subQty()"></button>
              <div class="item_option_count">{{ buyQty }}</div>
              <button type="button" class="bt_option plus" @click.stop="addQty()"></button>
            </div>
          </dd>
        </dl>
      </div>
      <!-- e : 상품옵션 -->
      <div class="bt_2nd">
        <button type="button" class="bt_rwhite" @click.stop="$emit('close')">닫기</button>
        <button type="button" class="bt_rblack" @click.stop="onSubmit(buyQty)">변경</button>
      </div>
    </div>
  </div>
</template>

<script>
import GoodsOptions from "@/assets/mixins/goods-options";

export default {
  mixins: [GoodsOptions],

  props: {
    hasCancelButton: {
      type: Boolean,
      default: false
    },
    giftYn : {
      type: Number,
      default: 2
    },
  },
  data: () => ({
    buyQty: undefined,
  }),
  computed: {
     genderStr() {
      if (this.goods.gndrKd == "M") {
        return "MEN";
      } else if (this.goods.gndrKd == "F") {
        return "WOMEN";
      } else if (this.goods.gndrKd == "K") {
        return "KIDS"
      } else if (this.goods.gndrKd == "Z") {
        return "UNISEX";
      } else {
        return "";
      }
    }
  },
  mounted() {
    this.buyQty = this.goods.buyQty;
    this.onAttrSelected('6');
    this.onAttrSelected('7');
  },
  methods: {
    onSubmit(buyQty,type) {
      if(this.selectItemOption['6'] === undefined || this.selectItemOption['7'] === undefined){
        this.$modal.error("옵션을 선택해주세요");
        return;
      }
      //let buyQty = this.buyQty;
      if (this.validate()) {
        let items = this.getFilteredItems();

        // 선택된 상품이 있다면...
        if (items.length > 0) {
          // 상품 재고 체크
          items = items.filter(x => x.stockQty >= buyQty);
          if (items.length > 0) {
            this.$attrs.update({ itemNo: items[0].itemNo, buyQty, type });
            this.$emit("close");
          } else {
            this.$modal.error("주문 가능한 수량을 초과 하였습니다.");
          }
        }
        else {
          this.$modal.error("선택할 수 없는 옵션입니다.");
        }
      } else {
        this.$modal.error("옵션을 선택해주세요.");
      }
    },

    /**
     * 상품 수량 증가
     * @param goods
     * @returns {Promise<void>}
     */
    addQty(){
       let buyQty = this.buyQty + 1;
     if (this.goods.maxOrdQty != null && this.goods.maxOrdQty < buyQty) {
        buyQty = this.buyQty;
        this.$modal.error('해당 상품의 최대 주문수량은 [' + this.goods.maxOrdQty + '] 입니다.');
      }
      let items = this.getFilteredItems();
      items = items.filter(x => x.stockQty >= 1);
      if(items.length == 1 && items[0].stockQty < buyQty){
         buyQty = this.buyQty;
         this.$modal.error('해당 상품의 최대 주문수량은 [' + items[0].stockQty + '] 입니다.');
       }
      this.buyQty = buyQty;
    },
    /**
     * 상품 수량 감소
     * @param goods
     * @returns {Promise<void>}
     */
    subQty() {
      let buyQty = this.buyQty - 1;
      if (buyQty < 1) {
        buyQty = 1;
        this.$modal.error('하나 보다 적게 선택할 수 없습니다.');
      } else if (this.goods.minOrdQty && buyQty < this.goods.minOrdQty) {
        buyQty = this.goods.minOrdQty;
        this.$modal.error('해당 상품의 최소 주문수량은 [' + this.goods.minOrdQty + '] 입니다.');
      }
      this.buyQty = buyQty;
    },
  }
};
</script>
