const middleware = {}

middleware['anonymous-only'] = require('../middleware/anonymous-only.js')
middleware['anonymous-only'] = middleware['anonymous-only'].default || middleware['anonymous-only']

middleware['category'] = require('../middleware/category.js')
middleware['category'] = middleware['category'].default || middleware['category']

middleware['member-only'] = require('../middleware/member-only.js')
middleware['member-only'] = middleware['member-only'].default || middleware['member-only']

middleware['user-info'] = require('../middleware/user-info.js')
middleware['user-info'] = middleware['user-info'].default || middleware['user-info']

export default middleware
