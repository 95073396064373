import Crema from "assets/models/Crema";

/**
 * 크리마 플러그인 초기화
 *
 * @param ctx
 * @param inject
 */
export default ({ $axios, store, app, env }, inject) => {
  // 카트 플러그인 초기화
  let crema = new Crema($axios, env.CREMA_TOKEN);
  app.$crema = crema;
  inject("crema", crema);
};
