<template>
  <!-- s: 장바구니 팝업 ; 개발작업시 인라인스타일 값 지우시면 됩니다! -->
  <div class="pop_wrap_all dimmed" :style="{ display: display }">
    <div class="mobpop_cart right">
      <div class="mob_hd pdlr30">
        <p class="mob_tit">MY CART
          <span
            v-if="$store.state.session.profile.cartGoodsCount > 0"
            class="cart_count"
          >
            [{{ $store.state.session.profile.cartGoodsCount }}]
          </span>
        </p>
        <div class="cart_utill">
          <label class="checkbox_wrap">
            <input type="checkbox" :checked="isAllChecked()" @click.stop="toggleCheckAll()">
            <i class="check_icon"></i>
            <span>전체선택</span>
          </label>
          <div class="remove_util">
            <button type="button" @click.stop="deleteSelectedGoodsFromCart()">선택삭제</button>
            <button type="button" @click.stop="deleteSoldOutGoodsFromCart()">품절삭제</button>
          </div>
        </div>
      </div>
      <div class="cart_scroll">
        <ul class="cart_itemli pdlr30" v-if="goodsList.length > 0">
          <li v-for="(goods, i) in goodsList" :key="i">
            <label class="checkbox_wrap">
              <input type="checkbox" :checked="isChecked(goods)" @click.stop="toggleCheck(goods)">
              <i class="check_icon"></i>
            </label>
            <div class="row_probox">
              <div class="thumb">
                <!--nuxt-link :to="{ path: `/goods`, query: { id: goods.goodsId } }"-->
                <a href="javascript:;" @click.stop="doRoute('/goods', {id: goods.goodsId})" >
                  <img
                    :src="getImageUrl(goods.imgPath)"
                    onerror="this.src='/images/no-img.jpg'"
                  />
                </a>
                <!--/nuxt-link-->
              </div>
              <div class="pro_sminfo">
                <span class="sort_tit">{{ genderStr(goods) }}</span>
                <p class="pro_name" style="font-size: 12px;">{{ goods.goodsNm }}</p>
                <div class="price" v-if="saleAmtPercent(goods) > 0">
                  <span class="dis_per" style="color: #f30000;font-size: 12px;">{{ formatPercentGoods(saleAmtPercent(goods), 'floor') }}</span>
                  <span class="org_pri" style="color: #20202c;font-size: 12px;">{{ formatAmount(goods.saleAmt) }}</span>
                  <span class="dis_pri">{{ formatAmount(beforeDiscountOrgAmt(goods)) }}</span>
                </div>
                <div class="price" v-else>
                  <span class="org_pri">{{ formatAmount(goods.saleAmt) }}</span>
                </div>
                <div class="pro_option">
                  <p class="optin">
                    옵션 : <span
                      v-for="name in sliceItemName(goods)"
                      :key="`${goods.goodsId}_${goods.itemNo}_${name}`"
                    >
                      {{ name }}
                    </span>
                  <!--                  / <span>S</span>-->
                  </p>
                  <p class="pro_count">수량 : <span>{{ goods.buyQty }}</span></p>
                </div>
              <!-- TODO: 옵션 셀렉트 -->
              <!--              <div class="pro_optsel">-->
              <!--                <select name="" id="" class="comon_select sm">-->
              <!--                  <option value="">1</option>-->
              <!--                  <option value="">2</option>-->
              <!--                  <option value="">3</option>-->
              <!--                </select>-->
              <!--                / <span class="optsel_count">2</span>-->
              <!--              </div> -->
              </div>
            </div>
            <div class="bt_2nd">
              <button type="button" class="bt_rwhite" style="border: 1px solid #ddd; border-radius: 25px;"  @click.stop="openOptionModal(goods)">옵션변경</button>
              <button
                type="button"
                class="bt_rgray"
                v-if="goods.salePsbCd === '30'"
              >
                품절
              </button>
              <!-- 품절일 때 -->
              <button
                type="button"
                class="bt_rblack"
                @click.stop="orderGoods(goods)"
                v-else
              >
                바로 구매
              </button>
            </div>
            <button class="remove_pro">닫기</button>
            <button
              type="button"
              class="remove_pro"
              @click.stop="removeGoods(goods)"
            >
              삭제
            </button>
          </li>
        </ul>
        <ul class="cart_itemli pd_mg" v-else>
          장바구니에 담긴 상품이 없습니다.
        </ul>
        <div class="ship_fee">
          <p>배송비 <span>{{ formatAmount(totalDlvrAmt) }}</span>원</p>
          <span v-if="deliveryPolicyList.length">
            ({{ formatAmount(deliveryPolicyList[0].buyPrc) }}원 미만 결제 시 {{ formatAmount(deliveryPolicyList[0].dlvrAmt) }}원)
          </span>
        </div>
        <div class="pay_infoli pdlr30">
          <strong>결제 예정 금액</strong>
          <ul>
            <li>
              <span>결제금액</span>
              <p>{{ formatAmount(totalGoodsAmt + totalPrmtDcAmt) }}원</p>
            </li>
            <li>
              <span>배송비</span>
              <p>+{{ formatAmount(totalDlvrAmt) }}원</p>
            </li>
            <li class="pdb15">
              <span>총 할인금액</span>
              <p>{{ formatAmount(totalDcAmt) }}원</p>
            </li>
            <li class="sm_ft pd8 border">
              <span>상품할인</span>
              <p>0원</p>
            </li>
            <li v-if="$store.state.session.isEmployee">
              <span>임직원 할인</span>
              <p>{{ formatAmount(-totalOfrstfDcAmt) }}원</p>
            </li>
            <li class="total_price">
              <span>총 결제 금액</span>
              <p>{{ formatAmount(totalAmt) }}원</p>
            </li>
            <li style="display:none">
              <label class="checkbox_wrap order_chk">
                <input type="checkbox" required v-model="onlineShoppingAgreement">
                <i class="check_icon"></i>
                <span>상품 주문 정보에 동의 합니다. <em class="txt_red">(필수)</em> <br>(전자상거래법 제 8조 제 2항)</span>
              </label>
            </li>
          </ul>
        </div>
        <div class="gray_polibox">
          <ul class="txt_bfli">
            <li>장바구니에 담긴 상품은 7일동안 보관됩니다. 7일이 지난 상품은 자동 삭제됩니다.</li>
            <li>장바구니에 최대 100개까지 상품 보관이 가능하며, 실제 구매시에는 가격이나 혜택이 변동 될 수 있습니다.</li>
            <li>장바구니에 담은 상품이 판매  종료가 되었을 경우 자동 삭제됩니다.</li>
          </ul>
        </div>
        <div class="with_paypro pdlr30" v-if="goodsList.length >= 0">
          <p class="mob_tit sm">함께 구매하면 좋은 상품</p>
          <div class="scrollbar_proli" >
            <client-only>
              <swiper ref="swiperOptions" :options="recommGoodsList.length == 1 ? Object.assign(swiperOptions, { init:false}) : swiperOptions" class="swiper-wrapper">
                <swiper-slide class="swiper-slide" v-for="(goods, index) in recommGoodsList" :key="`goods_${index}`">
                  <Goods :goods="goods" :is-none-options="['color']" inflow-method="CSTRT" :do-route-event="openCartModal" :customTextStyle="{fontSizePrice: '13px', fontSizeGender: '12px'}"></Goods>
                </swiper-slide>
              </swiper>
            </client-only>
          </div>
        </div>
        <button type="button" class="bttp_cart"
                @click.prevent="goCart" >
          <span>장바구니 자세히보기</span>
        </button>

        <div class="fix_btn block">
          <a href="#;" class="bt_rblack" @click.stop="onSubmit(1)">
            <p class="fix_count">총 <span>{{ totalCheckCnt }}</span>개</p>
            <p class="fix_price"><span> {{ formatAmount(totalAmt) }}</span>원 주문하기</p>
          </a>
        </div>
      </div>
      <button class="mob_close" @click.prevent="openCartModal()">닫기</button>
    </div>
  </div>
  <!-- e: 장바구니 팝업 ; display:none 상태입니다. -->
</template>

<script>
import Desktop from "@/components/pages/cart/mini/Desktop";
import SwiperGoods from "@/components/goods/swiper/Mobile";

export default {

  components: { SwiperGoods },
  extends: Desktop,

  activated() {
    this.fetchCart();
  },

  methods: {
    /**
     * 옵션 Parsing
     * @param goods
     * @returns {*}
     */
    sliceItemName(goods) {
      let itemNm = goods.itemNm;
      if (itemNm === undefined || itemNm === null) itemNm = "";
      return itemNm.split("/");
    },
  },

}
</script>

<style scoped>

</style>
