<template>
  <client-only>
    <li>
      <!--nuxt-link :to="{ path: '/goods', query: { id: goods.goodsId, exhbtNo: exhbtNo, inflowMethod: inflowMethod } }"-->
      <a href="javascript:;" @click.stop="goGoodsDetailPage('/goods', { id: goods.goodsId, exhbtNo: exhbtNo, inflowMethod: inflowMethod } )">
        <div class="thumb" @mouseover="hover=true" @mouseleave="hover=false">
          <!--img ref="targetRsTag" :src="getImageUrl(goods.imgPath)" onerror="this.src='/images/no-img.jpg'" v-if="hover==false">
          <img ref="targetRsTag2" :src="getImageUrl(goods.imgItemPath2)" onerror="this.src='/images/no-img.jpg'" v-else -->
          <img :src="rsImage" onerror="this.src='/images/no-img.jpg'" :alt="`${goods.goodsNm} 상품썸네일 이미지`" v-if="hover==false">
          <img :src="rsImage2" onerror="this.src='/images/no-img.jpg'" :alt="`${goods.goodsNm} 상품썸네일 hover 이미지`" v-else>
        </div>
        <div class="pro_sminfo">
          <div class="info_top_wrap">
            <span class="sort_tit" :style="{'font-size': getFontSizeGender(), 'color': getFontColorGender() }">{{ (genderStr) }}</span>
            <div class="color_chip sm" v-if="isShowColorWarp">
              <template v-for="(item, itemIndex) in getColorInfo" v-if="itemIndex < showMaxColorchip">
                <span :key="`item_${itemIndex}`" :style="{ 'background-color': getSplitData(item, '|', 1) }" v-if="['#ZZZZZZ'].indexOf(getSplitData(item, '|', 1)) < 0 && ['Multi','MultiColor'].indexOf(getSplitData(item, '|', 0)) < 0" :class="itemIndex == 5?'last_color':''"></span>
                <span :key="`item_${itemIndex}-m`" class="multi" v-else></span>
              </template>
              <template v-if="goods.rgbVal">
                <span :style="{ 'background-color': goods.rgbVal }" v-if="['#822882','#ZZZZZZ'].indexOf(goods.rgbVal) < 0"></span>
                <span class="multi" v-if="['#822882','#ZZZZZZ'].indexOf(goods.rgbVal) >= 0"></span>
              </template>
              <span class="colorCnt" v-if="getColorInfo.length > showMaxColorchip">+{{ getColorCnt(getColorInfo.length, showMaxColorchip) }}</span>
            </div>
          </div>
          <p class="pro_name" 
            :style="{'font-size': getFontSizePrice()}" 
            v-if="goods.prmtNm"
          >{{ goods.prmtNm }}</p>
          <p class="pro_name"
            :style="{'font-size': getFontSizePrice()}"
          >{{ goods.goodsNm }}</p>
          <div class="price">
            <span class="dis_per" :style="{'font-size': getFontSizePrice()}" v-if="dcAmt > 0">{{ formatPercent(dcAmt / saleAmt) }}</span>
            <span class="org_pri" :style="{'font-size': getFontSizePrice()}">{{ formatAmount(saleAmt - dcAmt) }}</span>
            <span class="dis_pri" :style="{'font-size': getFontSizePrice()}" v-if="dcAmt > 0">{{ formatAmount(saleAmt) }}</span>
          </div>
          <!-- <div class="star_wrap" v-if="isShowStarWarp"> -->
            <!-- cre.ma / 별점, 평점 / 스크립트를 수정할 경우 연락주세요 (support@cre.ma) -->
            <!-- <div class="crema-product-reviews-score" :data-product-code="goods.goodsId" data-star-style="multiple" data-star-size="16px" data-font-size="14px" data-hide-if-zero="0" :data-format="`{{{ stars }}} {{{ reviews_count }}}`"></div>
          </div> -->
          <!-- <div class="pro_tags" v-if="isShowBadgeWarp">
            <span v-if="goods.newYn == 'Y'">NEW</span>
            <span v-if="goods.bestYn == 'Y'">BEST</span>
          </div> -->
        </div>
      </a>
      <!--/nuxt-link-->
    </li>
  </client-only>
</template>

<script>
import numeral from "numeral";

export default {

  props: {
    goods: Object,
    isNoneOptions: Array,
    // rsWidth: Number
    exhbtNo : {
      type: Number,
      required: false
    },
    inflowMethod : {
      type: String,
      required: false
    },
    doRouteEvent : {
      type: Object,
      required: false
    },
    customTextStyle : {
      type: Object,
      required: false,
      default() {
        return {
          fontSizePrice: null, //가격텍스트 font-size
          fontColorGender: null, //성별Text 컬러
          colorchipSize: null,
          fontSizeGender: null,
        }
      }
    },
    showMaxColorchip: {
      type: Number,
      default: 6,
      required: false
    }
  },
  data() {
    return {
      isShowOption:[],
      rsImgPath: this.goods.imgPath,
      rsImgPath2: this.goods.imgItemPath2,
      hover: false,
      rsWidth: 600,
      overColor: 0,
    }
  },

  computed: {
    saleAmt() {
      if (this.goods.orgAmtHiddenYn != "Y") {
        return numeral(this.goods.orgSaleAmt).value();
      } else {
        return numeral(this.goods.saleAmt).value();
      }
    },
    offDcAmt() {
      let orgSaleAmt = numeral(this.goods.orgSaleAmt).value();
      let saleAmt = numeral(this.goods.saleAmt).value();
      if (this.goods.orgAmtHiddenYn != "Y") {
        return orgSaleAmt - saleAmt;
      } else {
        return 0;
      }
    },
    dcAmt() {
      let prmtDcAmt = numeral(this.goods.prmtDcAmt).value();
      return this.offDcAmt + prmtDcAmt;
    },
    genderStr() {
      if (this.goods.gndrKd == "M") {
        return "MEN";
      } else if (this.goods.gndrKd == "F") {
        return "WOMEN";
      } else if (this.goods.gndrKd == "K") {
        return "KIDS"
      } else if (this.goods.gndrKd == "Z") {
        return "UNISEX";
      } else {
        return "";
      }
    },
    estmScore(){
      let estmScore = numeral(this.goods.estmScore).value() * 0.2;
      return estmScore;
    },

    isShowStarWarp() {
      // this.isShowOption = this.isNoneOptions
      if (this.isNoneOptions !== undefined && this.isNoneOptions.indexOf("star")> -1) {
        return false
      }
      return true;
    },
    isShowColorWarp() {
      // this.isShowOption = this.isNoneOptions
      if (this.isNoneOptions !== undefined && this.isNoneOptions.indexOf("color")> -1) {
          return false
      }
      return true;
    },
    isShowBadgeWarp() {
      // this.isShowOption = this.isNoneOptions
      if (this.isNoneOptions !== undefined && this.isNoneOptions.indexOf("badge")> -1) {
        return false
      }
      return true;
    },
    rsImage() {
      return this.rsImgPath
    },
    rsImage2() {
      return this.rsImgPath2
    },
    getColorInfo() {
      return this.splitComma(this.goods.colorInfo).filter(x => x != '');
    },
  },

  methods: {
    goGoodsDetailPage(path, query) {
      if(this.doRouteEvent) {
        this.doRouteEvent();
      }
      query = query || {};
      this.$router.push({path: path, query: query});
    },
    getColorCnt(cnt, len){
        var count = cnt - len
        return count;
    },
    getFontSizePrice(){
      return this.customTextStyle.fontSizePrice? this.customTextStyle.fontSizePrice : '';
    },
    getFontColorGender(){
      return this.customTextStyle.fontColorGender? this.customTextStyle.fontColorGender : '';
    },
    getColorchipSize(){
      return this.customTextStyle.colorchipSize? this.customTextStyle.colorchipSize : '';
    },
    getFontSizeGender(){
      return this.customTextStyle.fontSizeGender? 
                this.customTextStyle.fontSizeGender : (this.getFontSizePrice() ? this.getFontSizePrice() : '');
    },
  },

  /*
  data() {
    return {
      hover: false
    }
  },
  */
  created () {

      this.rsImgPath = this.goods.imgPath,
      this.rsImgPath2 = this.goods.imgItemPath2;

  },

  updated() {
    if(!this.rsWidth) {
      if(this.$device.isMobile){
        //20231110 모바일도 400으로 변경
        this.rsWidth = 400;
      } else {
        this.rsWidth = 600;
      }
    }

    try {
      if( this.goods ){
        this.rsImgPath = this.getImageUrlRS(this.goods.imgPath, this.rsWidth);
        this.rsImgPath2 = this.getImageUrlRS((this.goods.imgItemPath1  ? this.goods.imgItemPath1 : this.goods.imgItemPath2) || this.goods.imgPath, this.rsWidth);
      } else {
        this.rsImgPath = this.getImageUrl(this.goods.imgPath);
        this.rsImgPath2 = this.getImageUrl((this.goods.imgItemPath1 ? this.goods.imgItemPath1 : this.goods.imgItemPath2) || this.goods.imgPath);
      }
    } catch (e) {
      this.rsImgPath = this.goods.imgPath;
      this.rsImgPath2 = (this.goods.imgItemPath1  ? this.goods.imgItemPath1 : this.goods.imgItemPath2) || this.goods.imgPath;
    }
  },

  mounted() {


    //CDN에 RS파라미터 넘기기
    if(!this.rsWidth) {
      if(this.$device.isMobile){
        this.rsWidth = 250;
      } else {
        this.rsWidth = 600;
      }
    }

    try {
      if( this.goods ){
        this.rsImgPath = this.getImageUrlRS(this.goods.imgPath, this.rsWidth);
        this.rsImgPath2 = this.getImageUrlRS(this.goods.imgItemPath1  ? this.goods.imgItemPath1 : this.goods.imgItemPath2, this.rsWidth);
      } else {
        this.rsImgPath = this.getImageUrl(this.goods.imgPath);
        this.rsImgPath2 = this.getImageUrl(this.goods.imgItemPath1 ? this.goods.imgItemPath1 : this.goods.imgItemPath2);
      }
    } catch (e) {
      this.rsImgPath = this.goods.imgPath;
      this.rsImgPath2 = this.goods.imgItemPath1  ? this.goods.imgItemPath1 : this.goods.imgItemPath2;
    }
  },
};
</script>
