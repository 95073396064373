
export default {
  methods: {
    /**
     * GFR 회원 L.POINT 전환 후 
     * /conversion 리턴페이지에서 사용할 로그아웃
     * SSO 로그인 상태가 아니기 때문에 일반 로그아웃만 진행
     */
    async onConversionLogout() {
      return new Promise(async (resolve, reject) => {
        if (this.$store.state.session.isLotteMembers) {
          try {
            await this.$axios.$post("/api/logout");
            await this.clearAfterLogout()
          } catch (error) {
            console.error(error)
          } finally {
            resolve()
          }
        }
      })
    },

    /**
     * 로그아웃 버튼 클릭시
     * L.Point 회원일 경우 sso 로그아웃 후 기존 로그아웃
     * L.Point 회원이 아닐 경우 기존 로그아웃
     */
    async onClickLogout(redirect = true) {
      if (this.$store.state.session.isLotteMembers) {
        try {
          const acesTkn = this.$store.state.session.lMembersAcesTkn

          if (!acesTkn) {
            await this.$axios.$post("/api/logout");
            await this.clearAfterLogout()
            if (redirect) {
              return await this.$router.push("/", () => window.location.reload());
            }
          }

          const sso = this.$ssoLib.initSsoLogout({ acesTkn })
    
          sso.callLogout({
            callback: async (rspDta) => {
              await this.$axios.$post("/api/logout");
              await this.clearAfterLogout()
              if (redirect) {
                return await this.$router.push("/", () => window.location.reload());
              }
            }
          });
        } catch (error) {
          console.error(error)
        }
      } else {
        try {
          await this.$axios.$post("/api/logout");
          await this.clearAfterLogout()
          if (redirect) {
            return await this.$router.push("/", () => window.location.reload());
          }
        } catch (error) {
          console.error(error)
        }
      }
    },

    /**
     * === L.Point 회원 전환
     */
    async convertGfrToLPoint() {
      try {
        let mbrNo = this.$store.state.session.profile.id

        if (!mbrNo) {
          try {
            const { id } = await this.$axios.$get('/api/user/me')
            mbrNo = id
          } catch (error) {
            console.error(error)
          }
        }

        const { memberInfo } = await this.$axios.$post(`/api/lmembers/sso/memberInfoForLpointJoin?mbrNo=${mbrNo}`)
        // console.log('memberInfo', memberInfo)

        let referrer = null;
        if (['/order', '/gift'].includes(this.$route.path)) {
          referrer = this.$route.path.substring(1)
          console.log('referrer is ', referrer)
        }

        if (!memberInfo.ciNo) {
          return this.onClickLMembersJoinBtn(false, true)
        }

        const sso = this.$ssoLib.initSsoJoin()
        sso.callScreen({
          akUrl: '/exView/join/mbrJoin_21_001',          // 요청URL
          akDta: {
            ccoCstNo : memberInfo.ccoCstNo, //O 제휴사고객번호
            ctfYn : memberInfo.ctfYn, //O 인증여부     하드코딩
            ccoOnlId : memberInfo.ccoOnlId, //X 제휴사온라인ID
            cstNm : memberInfo.cstNm,  //O 고객명
            bird : memberInfo.bird, //O 생년월일
            maFemDvC : memberInfo.maFemDvC,  //O 남녀구분코드  
            frnYn : memberInfo.frnYn, //O 외국인여부   하드코딩
            ciNo : memberInfo.ciNo, 
            elcAdd : memberInfo.elcAdd, //O 전자주소   
            mbzNoC : memberInfo.mbzNoC, //X 이동전화사업자번호   
            mmtExno : memberInfo.mmtExno, //X 이동전화국번      
            mtlno : memberInfo.mtlno, //X 이동전화개별번호      
            addDc : memberInfo.addDc, //X 주소구분코드   
            naddYn : memberInfo.naddYn, //X 신주소여부      
            pstNo : memberInfo.pstNo, //X 우편번호      
            pnadd : memberInfo.pnadd,  //X 우편번호주소   
            bpsnoAdd1 : memberInfo.bpsnoAdd1,  //X 우편번호외주소1      
            telZonNo : memberInfo.telZonNo, //X 전화번호지역번호      
            exno : memberInfo.exno,  //X 전화국번  
            tlno : memberInfo.tlno, //X 전화개별번호 
            ccoEmailRcvYn : memberInfo.ccoEmailRcvYn, //X 제휴사 EMAIL 수신여부     
            ccoSmsRcvYn : memberInfo.ccoSmsRcvYn  //X 제휴사 SMS수신여부    
            // addClngYn : memberInfo.addClngYn, //X 주소정제여부      
            // fdstPstNo : memberInfo.fdstPstNo,  //X 기초구역우편번호   
            // bpsnoAdd2 : memberInfo.bpsnoAdd2,  //X 우편번호외주소2   
            // bldMgno : memberInfo.bldMgno, //X 건물관리번호  
            // pnaddBk : memberInfo.pnaddBk, //X 우편번호주소백업   
            // bpsnoAdd1Bk : memberInfo.bpsnoAdd1Bk,  //X 우편번호외주소1백업     
            // bpsnoAdd2Bk : memberInfo.bpsnoAdd2Bk, //X 우편번호외주소2백업      
          },
          rturUrl: referrer ? `${this.$env.siteUrl}/conversion?referrer=${referrer}` : `${this.$env.siteUrl}/conversion`,      // 제휴사리턴URL
          rturUrlCaloMethd: 'GET'                     //  리턴URL호출메소드
       });
      } catch (error) {
        console.error('[convertMember error]', error)  
      }
    },

    /**
     * =========================================
     * L.Point 회원으로 가입(전환) 핸들러
     * =========================================
     */
    async onClickLMembersJoinBtn(isAfterLogoutHandler = false, noCi = false) {
      const isOrderRedirect = ['/gift', '/order'].includes(this.$route.path)
      const ref = this.$route.path.substring(1)
      console.log('isOrderRedirect', isOrderRedirect, ref)

      // 통합회원일 경우 메인페이지로 이동
      if (this.$store.state.session.isLotteMembers) {
        return this.$router.push('/my-page/lpoint')
      }

      // (FIXME) 로그인 후처리 유도팝업에서 호출한 경우 통합회원전환 페이지로 이동
      if (isAfterLogoutHandler) {
        return this.convertGfrToLPoint()
      }

      // Ci가 없다고 파라미터로 넘어오면 로그아웃 후 회원가입 페이지로 이동
      if (noCi) {
        await this.$axios.$post("/api/logout");
        await this.clearAfterLogout()
        return isOrderRedirect ? this.$router.push(`/join?referrer=${ref}_join`) : this.$router.push('/join')
      }

      // 비회원일 경우 회원가입 페이지로 이동
      if (this.$store.state.session.isGuest) {
        return isOrderRedirect ? this.$router.push(`/join?referrer=${ref}_join`) : this.$router.push('/join')

      // 회원일 경우 통합회원전환 페이지로 이동
      } else {
        return this.convertGfrToLPoint()
      }
    },

    /**
     * =========================================
     * L.Point 회원으로 가입(전환) 핸들러 (logout, redirect)
     * =========================================
     */
    // async onClickLMembersJoinBtn(isAfterLogoutHandler = false) {
    //   const isOrderRedirect = ['/gift', '/order'].includes(this.$route.path)
    //   const ref = this.$route.path.substring(1)
    //   console.log('isOrderRedirect', isOrderRedirect, ref)

    //   if (this.$store.state.session.isGuest) {
    //     if (isAfterLogoutHandler) {
    //       await this.$axios.$post("/api/logout");
    //       await this.clearAfterLogout()
    //       window.location.href = isOrderRedirect ? `/join?referrer=${ref}_join` : '/join';  
    //     } else {
    //       isOrderRedirect ? this.$router.push(`/join?referrer=${ref}_join`) : this.$router.push('/join')
    //     }
    //   } else {
    //     await this.$axios.$post("/api/logout");
    //     await this.clearAfterLogout()
    //     window.location.href = isOrderRedirect ? `/join?referrer=${ref}_join` : '/join';
    //   }
    // },

    async setCookie (name, value, exp) {
      var date = new Date();
      date.setTime(date.getTime() + (exp*24*60*60*1000) );
      document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';
    },

    clearAfterLogout() {
      return new Promise((resolve, reject) => {
        try {
          // clear token
          window.sessionStorage.removeItem('lMembersTknObj')
          this.$cookies.remove(process.env.SSO_RNW_TKN_KEY)
    
          // clear store/session
          this.$store.commit("session/clearLMembersTkn");              
          this.$store.commit("session/clear");
    
          // clear auto-login
          this.setCookie("AUTO_LOGIN", "N", 0);
    
          // clear crema
          window.cremaAsyncInit = function () {
            crema.init(null, null);
          };
        } catch (error) {
          console.log('[clearAfterLogout]: failed', error)
          
        } finally {
          resolve()
        }
      })
    }
  }
};